<section class="error-area">
  <div class="error-item">
    <div class="d-table">
      <div class="d-table-cell">
        <div class="error-text">
          <h1>Coming Soon!</h1>
          <p>Sorry! The Page still in development</p>
          <span
            >Oops! The page you are looking for does not exit. it might been
            still under development.</span
          >
          <a routerLink="/">Return to Home</a>
        </div>
      </div>
    </div>
  </div>
</section>
