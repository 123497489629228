import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { NavbarComponent } from "./components/layouts/navbar/navbar.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { PrivacyPolicyComponent } from "./components/pages/privacy-policy/privacy-policy.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { StoriesDetailsComponent } from "./components/pages/stroies-details/stories-details.component";
import { DoctorDetailsComponent } from "./components/pages/doctor-details/doctor-details.component";
import { StoriesComponent } from "./components/pages/stories/stories.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { ResourcesComponent } from "./components/pages/resources/resources.component";
import { StoryLookupComponent } from "./components/pages/story-lookup/story-lookup.component";
import { AspiringParentsComponent } from "./components/pages/aspiring-parents/aspiring-parents.component";
import { DonorsComponent } from "./components/pages/donors/donors.component";
import { BecomeDonorComponent } from "./components/pages/become-donor/become-donor.component";
import { SurrogatesComponent } from "./components/pages/surrogates/surrogates.component";
import { BecomeSurrogateComponent } from "./components/pages/become-surrogate/become-surrogate.component";
import { SurrogacyComponent } from "./components/pages/surrogacy/surrogacy.component";
import { EggDonationComponent } from "./components/pages/egg-donation/egg-donation.component";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserService } from "./components/service/user/user.service";
import { HttpClientModule } from "@angular/common/http";
import { NgxWebstorageModule } from "ngx-webstorage";
import { SessionService } from "./components/guards/session.service";
import { AuthGuard } from "./components/guards/auth.guard";
import { Authenticate } from "./components/guards/authenticate.service";
import { DashLayoutComponent } from "./components/pages/layouts/dashboard-layout/dash-layout.component";
import { SidebarModule } from "./components/pages/layouts/dashboard-layout/dashboard/sidebar/sidebar.module";
import { NavbarModule } from "./components/pages/layouts/dashboard-layout/dashboard/shared/navbar/navbar.module";
import { ResetPasswordComponent } from "./components/pages/reset-password/reset-password.component";
import { NgxMaskDirective, NgxMaskPipe } from "ngx-mask";
import { AdminLoginComponent } from "./components/pages/admin-login/admin-login.component";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeOneComponent,
    AboutComponent,
    SignupComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    ResourcesComponent,
    FaqComponent,
    ErrorComponent,
    StoriesDetailsComponent,
    DoctorDetailsComponent,
    StoriesComponent,
    // BlogComponent,
    // BlogDetailsComponent,
    ContactComponent,
    ComingSoonComponent,
    StoryLookupComponent,
    AspiringParentsComponent,
    DonorsComponent,
    BecomeDonorComponent,
    SurrogatesComponent,
    BecomeSurrogateComponent,
    SurrogacyComponent,
    EggDonationComponent,
    DashLayoutComponent,
    ResetPasswordComponent,
    AdminLoginComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastModule,
    CommonModule,
    SidebarModule,
    NavbarModule,
    NgxWebstorageModule.forRoot({ prefix: "fertilityneeds", separator: "-" }),
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    UserService,
    SessionService,
    Authenticate,
    AuthGuard,
    MessageService,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
