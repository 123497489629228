import { Component } from "@angular/core";

@Component({
  selector: "app-surrogacy",
  templateUrl: "./surrogacy.component.html",
  styleUrls: ["./surrogacy.component.scss"],
})
export class SurrogacyComponent {
  openFile(fileName: string) {
    window.open(`assets/needs-documents/${fileName}.pdf`, "_blank");
  }
}
