<div class="page-title-area page-title-about">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>ABOUT US</h2>
      </div>
    </div>
  </div>
</div>

<div class="about-area pt-100">
  <div class="container">
    <h2>ABOUT US | Letter from our Founder</h2>
    <hr />
    <div class="row align-items-center">
      <!-- <div class="col-lg-6">
        <div class="about-item">
          <div class="about-left">
            <img src="assets/img/home/12.png" alt="About" />
          </div>
        </div>
      </div> -->
      <div class="col-lg-12">
        <div class="about-item about-right">
          <img src="assets/img/home-one/home-bg5.png" alt="About" />
          <p>
            I want to extend to you a personal welcome to www.fertilityneeds.com
            NEEDS’ state-of-the-art online community that brings aspiring
            parents together with the egg donors, surrogates, medical providers,
            and others who can help them fulfill their dream of parenthood.
          </p>

          <p>
            I founded NEEDS in 1992, soon after my daughter, Marisa, was born
            with the help of a surrogate and egg donor. (NEEDS was formerly
            called NESPA - New England Surrogate Parenting Advisors & Egg
            Donation.) The early 1990s were still the “early days” of
            collaborative reproduction, and I found the experience to be
            confusing, complicated, and awkward – though also greatly rewarding.
            With NEEDS, I wanted to maximize the rewards of collaborative
            reproduction by offering other aspiring parents an easier, less
            confusing experience than I had.
          </p>

          <p>
            Today, more than a decade later, collaborative reproduction has come
            into its own. Especially because of the Internet, aspiring parents
            can be much better informed. And because collaborative reproduction
            is increasingly common, infertility is no longer such a stigma.
          </p>

          <p>
            NEEDS has changed too. We’re constantly looking for new ways to
            improve our services to aspiring parents. And we’re continually
            expanding and enhancing our collaborative reproduction community.
            This community includes medical, psychiatric, and legal partners as
            well as the marvelous women who so generously offer themselves as
            egg donors and surrogates to make our most precious dreams come
            true.
          </p>

          <p>
            As aspiring parents, it’s an exciting time. You now have more
            options and more resources available to you than ever before. With
            www.fertilityneeds.com, NEEDS puts these options and resources at
            your fingertips while continuing to fulfill our mission as your
            trusted guide through the emotional, and still challenging, process
            of collaborative reproduction.
          </p>
          <p>
            Sincerely, <br />
            Angela Figueroa, Ed.D.<br />
            NEEDS Founder (retired)
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="pt-100">
    <app-story-lookup></app-story-lookup>
  </div>
</div>
