<footer class="pb-100">
  <div class="newsletter-area">
    <!-- <div class="container">
      <div class="row newsletter-wrap align-items-center">
        <div class="col-lg-6">
          <div class="newsletter-item">
            <h2>Join Our Newsletter</h2>
            <p>
              The latest industry insights, tips, and exclusive offers. Don't
              miss out on the chance to elevate your knowledge and be the first
              to hear about exciting developments. Subscribe today!
            </p>
          </div>
        </div>

        <div class="col-lg-6">
          <div class="newsletter-item">
            <h5>{{ message }}</h5>
            <div class="newsletter-form">
              <form [formGroup]="form" class="newsletter-form">
                <input
                  type="email"
                  formControlName="email"
                  class="form-control"
                  placeholder="Enter Your Email"
                  name="EMAIL"
                />
                <button (click)="submit()" class="btn newsletter-btn">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-lg-4">
        <div class="footer-item">
          <div class="footer-contact">
            <h3>Contact</h3>
            <ul>
              <li>
                <i class="icofont-ui-message"></i>
                <a href="mailto:info@fertilityneeds.com"
                  >info@fertilityneeds.com</a
                >
              </li>
              <li>
                <i class="icofont-stock-mobile"></i>
                <a href="tel:+07554332322">Call: 1(781)-353-1300</a>
              </li>
              <li>
                <i class="icofont-location-pin"></i>
                USA
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-2">
        <div class="footer-item">
          <div class="footer-quick">
            <h3>Quick Links</h3>
            <ul>
              <li><a routerLink="/about">About</a></li>
              <li><a routerLink="/blog">Blog</a></li>
              <li>
                <a routerLink="/blog-details">Our Expertise</a>
              </li>
              <li><a routerLink="/faq">Faq</a></li>
              <li><a routerLink="/contact">Contact us</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-quick">
            <h3>Resources</h3>
            <ul>
              <li><a routerLink="/aspiring-parents">Aspiring Parents</a></li>
              <li><a routerLink="/egg-donation">Egg Donation</a></li>
              <li><a routerLink="/surrogate">Surrogacy</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-sm-6 col-lg-3">
        <div class="footer-item">
          <div class="footer-feedback">
            <h3>Feedback</h3>
            <h3>{{ messageFeedback }}</h3>
            <form *ngIf="!messageFeedback" [formGroup]="formFeedback">
              <div class="form-group">
                <input
                  type="text"
                  formControlName="name"
                  class="form-control"
                  placeholder="Name"
                />
              </div>
              <div class="form-group">
                <input
                  type="email"
                  class="form-control"
                  formControlName="email"
                  placeholder="email"
                />
              </div>
              <div class="form-group">
                <textarea
                  class="form-control"
                  formControlName="message"
                  id="your_message"
                  rows="5"
                  placeholder="Message"
                ></textarea>
              </div>
              <div class="text-left">
                <button
                  type="submit"
                  (click)="feedback()"
                  class="btn feedback-btn"
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-toast></p-toast>
</footer>

<div class="copyright-area">
  <div class="container">
    <div class="copyright-item">
      <p>
        Copy Rights © {{ currentYear }} Fertility Needs | All rights reserved
      </p>
    </div>
  </div>
</div>
