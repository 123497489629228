import { Injectable } from "@angular/core";
import { SessionService } from "./session.service";
import { IUser } from "../models/user";
import { ApiService } from "../service/api.services";

@Injectable()
export class Authenticate extends ApiService {
  constructor(private service: SessionService) {
    super();
  }

  public get User(): IUser {
    return this.service.user;
  }

  public ClearSession(): IUser {
    this.service.user = null;
    return this.service.user;
  }
}
