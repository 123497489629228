<div class="signup-area">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 ptb-100">
        <div class="signup-item ptb-100">
          <div class="signup-head">
            <h2>Admin Login</h2>
            <p>
              Not an Admin?
              <a routerLink="/login">Login as user</a>
            </p>
          </div>

          <div class="signup-form">
            <form [formGroup]="form">
              <div class="row">
                <div *ngIf="errorMessage" class="error-message">
                  *{{ errorMessage }}!
                </div>
                <div class="col-lg-12">
                  <div class="form-group">
                    <input
                      type="email"
                      class="form-control"
                      formControlName="email"
                      placeholder="Your Email"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <input
                      type="password"
                      class="form-control"
                      formControlName="password"
                      placeholder="Password"
                    />
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group">
                    <div class="forgot-pass">
                      <a routerLink="/reset-password">Forgot Password?</a>
                    </div>
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="text-center">
                    <button
                      type="submit"
                      (click)="submit()"
                      [disabled]="this.form.invalid"
                      class="btn signup-btn"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="blog-area pt-100 pb-70"></section>
