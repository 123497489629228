<div class="loader">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
</div>
