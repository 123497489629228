import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../service/user/user.service";
import { SessionService } from "../../guards/session.service";
import { UserType } from "../../layouts/models/usertype";
import { BaseLayout } from "../../layouts/baselayout";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent extends BaseLayout implements OnInit {
  constructor(
    private fb: FormBuilder,
    private service: UserService,
    private auth: SessionService,
    private router: Router,
    route: ActivatedRoute,
  ) {
    super(route);
  }

  async ngOnInit(): Promise<void> {
    const params = await this.getQueryParams();
    this.token = params["token"];
    const email = params["email"];
    if (this.token) {
      this.form.controls.email.setValue(email);
      this.form.controls.password.setValue(this.token);
    }
    this.auth.clearSession();
  }

  errorMessage: string;
  token: string;

  public form: FormGroup = this.fb.group({
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  async submit() {
    if (this.form.valid) {
      const result = this.token
        ? await this.service.loginUser(this.form.value)
        : await this.service.login(this.form.value);
      if (result) {
        this.auth.user = result;
        if (result.userType === UserType.Admin) {
          this.router.navigate(["/auth/admin/dashboard"]);
        } else {
          this.router.navigate(["/auth/user-dashboard"]);
        }
      } else {
        this.errorMessage = this.service.errorMessage;
      }
    }
  }
}
