import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeOneComponent } from "./components/pages/home-one/home-one.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { FaqComponent } from "./components/pages/faq/faq.component";
import { LoginComponent } from "./components/pages/login/login.component";
import { SignupComponent } from "./components/pages/signup/signup.component";
import { AppointmentComponent } from "./components/pages/appointment/appointment.component";
import { ComingSoonComponent } from "./components/pages/coming-soon/coming-soon.component";
import { StoriesDetailsComponent } from "./components/pages/stroies-details/stories-details.component";
import { StoriesComponent } from "./components/pages/stories/stories.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { ResourcesComponent } from "./components/pages/resources/resources.component";
import { AspiringParentsComponent } from "./components/pages/aspiring-parents/aspiring-parents.component";
import { DonorsComponent } from "./components/pages/donors/donors.component";
import { BecomeDonorComponent } from "./components/pages/become-donor/become-donor.component";
import { SurrogatesComponent } from "./components/pages/surrogates/surrogates.component";
import { BecomeSurrogateComponent } from "./components/pages/become-surrogate/become-surrogate.component";
import { SurrogacyComponent } from "./components/pages/surrogacy/surrogacy.component";
import { EggDonationComponent } from "./components/pages/egg-donation/egg-donation.component";
import { AuthGuard } from "./components/guards/auth.guard";
import { DashLayoutComponent } from "./components/pages/layouts/dashboard-layout/dash-layout.component";
import { ResetPasswordComponent } from "./components/pages/reset-password/reset-password.component";
import { AdminLoginComponent } from "./components/pages/admin-login/admin-login.component";

const routes: Routes = [
  { path: "", component: HomeOneComponent },
  // { path: "home-two", component: HomeTwoComponent },
  // { path: "home-three", component: HomeThreeComponent },
  { path: "about", component: AboutComponent },
  { path: "appointment-old", component: AppointmentComponent },
  // { path: "departments-old", component: DepartmentsComponent },
  // { path: "testimonials", component: TestimonialsComponent },
  { path: "signup", component: SignupComponent },
  { path: "login", component: LoginComponent },
  // { path: "privacy-policy-old", component: PrivacyPolicyComponent },
  { path: "resources", component: ResourcesComponent },
  { path: "faq", component: FaqComponent },
  // { path: "error", component: ErrorComponent },
  { path: "coming-soon", component: ComingSoonComponent },
  // { path: "services", component: ServicesComponent },
  { path: "stories-details/:id", component: StoriesDetailsComponent },
  { path: "stories", component: StoriesComponent },
  // { path: "doctor-details", component: DoctorDetailsComponent },
  // { path: "blog", component: BlogComponent },
  // { path: "blog-details", component: BlogDetailsComponent },
  { path: "contact", component: ContactComponent },
  { path: "aspiring-parents", component: AspiringParentsComponent },
  { path: "donor", component: DonorsComponent },
  { path: "become-donor", component: BecomeDonorComponent },
  { path: "surrogate", component: SurrogatesComponent },
  { path: "become-surrogate", component: BecomeSurrogateComponent },
  { path: "surrogacy", component: SurrogacyComponent },
  { path: "egg-donation", component: EggDonationComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "admin", component: AdminLoginComponent },

  {
    path: "",
    canActivateChild: [AuthGuard],
    children: [
      {
        path: "auth",
        component: DashLayoutComponent,
        loadChildren: () =>
          import(
            "./components/pages/layouts/dashboard-layout/dash-layout.module"
          ).then((m) => m.DashLayoutModule),
      },
    ],
  },
  // Here add new pages component
  { path: "**", component: ErrorComponent }, // This line will remain down from the whole pages component list
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
