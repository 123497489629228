import { Injectable } from "@angular/core";
import { ApiService } from "../api.services";
import { HttpClient } from "@angular/common/http";
import { IUser } from "../../models/user";
import { ContactUS } from "../../models/contact-us";

@Injectable({
  providedIn: "root",
})
export class LeadService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  public async create(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("lead/subscribe", user);
    return response.status ? response.innerData : null;
  }

  public async get(id: number): Promise<IUser> {
    const response = await this.getResponse<IUser>(`lead/subscribe/${id}`);
    return response.status ? response.innerData : null;
  }

  public async createFeedback(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("lead/feedback", user);
    return response.status ? response.innerData : null;
  }

  public async contactUs(contact: ContactUS): Promise<ContactUS> {
    const response = await this.postResponse<ContactUS>(
      "lead/contact-us",
      contact,
    );
    return response.status ? response.innerData : null;
  }

  public async getFeedback(id: number): Promise<IUser> {
    const response = await this.getResponse<IUser>(`lead/feedback`);
    return response.status ? response.innerData : null;
  }
}
