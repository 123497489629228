<div class="page-title-area page-title-five">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>Contact</h2>
      </div>
    </div>
  </div>
</div>

<div class="location-area">
  <div class="container">
    <div class="row location-wrap">
      <div class="col-sm-6 col-lg-4">
        <div class="location-item">
          <i class="icofont-location-pin"></i>
          <h3>Location</h3>
          <p>USA</p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-4">
        <div class="location-item">
          <i class="icofont-ui-message"></i>
          <h3>Email</h3>
          <ul>
            <li>info@fertilityneeds.com</li>
          </ul>
        </div>
      </div>

      <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
        <div class="location-item">
          <i class="icofont-ui-call"></i>
          <h3>Phone</h3>
          <ul>
            <li>Phone: 1(781)-353-1300</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="drop-area pt-100">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-7 p-0">
        <div class="drop-item drop-img">
          <div class="drop-left">
            <h2>Drop your message for any info or question.</h2>

            <h3>{{ messageFeedback }}</h3>
            <form *ngIf="!messageFeedback" [formGroup]="formFeedback">
              <div class="row">
                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="name"
                      formControlName="name"
                      class="form-control"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      formControlName="email"
                      class="form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="phone_number"
                      mask="(000) 000-0000"
                      placeholder="(000) 000-0000"
                      formControlName="phoneNumber"
                      class="form-control"
                      placeholder="Phone"
                    />
                  </div>
                </div>

                <div class="col-sm-6 col-lg-6">
                  <div class="form-group">
                    <input
                      type="text"
                      name="msg_subject"
                      class="form-control"
                      formControlName="subject"
                      placeholder="Subject"
                    />
                  </div>
                </div>

                <div class="col-md-12 col-lg-12">
                  <div class="form-group">
                    <textarea
                      name="message"
                      formControlName="message"
                      class="form-control"
                      cols="30"
                      rows="5"
                      placeholder="Your Message"
                    ></textarea>
                  </div>
                </div>
                <p-toast></p-toast>
                <div class="col-md-12 col-lg-12">
                  <button type="submit" (click)="feedback()" class="drop-btn">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-5 p-0">
        <div
          class="speciality-item speciality-right speciality-right-two speciality-right-three"
        >
          <img src="assets/img/home/12.png" alt="Contact" />

          <div class="speciality-emergency">
            <div class="speciality-icon">
              <i class="icofont-ui-call"></i>
            </div>
            <h3>Emergency Call</h3>
            <p>1(781)-353-1300</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div class="contact-map-area">
  <div id="map">
    <iframe
      src="
      https://www.google.com/maps/embed?Massachusetts/@42.0369155,-71.6835355,8z/data=!3m1!4b1!4m6!3m5!1s0x89e3656de973bffd:0x45c6d03655830d1c!8m2!3d42.4072107!4d-71.3824374!16zL20vMDVrN3Ni?hl=en&entry=ttu"
    ></iframe>
  </div>
</div>
