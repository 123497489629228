import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { UserType } from "src/app/components/layouts/models/usertype";
import { SessionService } from "src/app/components/guards/session.service";
import { RouteInfo } from "../../sidebar/sidebar.component";
import { IUser } from "src/app/components/models/user";

@Component({
  // moduleId: module.id,
  selector: "navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;

  constructor(
    location: Location,
    private renderer: Renderer2,
    private element: ElementRef,
    private router: Router,
    private session: SessionService,
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.user = session.user;
    this.generateSidebarItems();
  }

  user: IUser;
  public get appRoute(): string {
    if (this.user.userType) {
      return this.session.getAppRouteLink(this.user.userType);
    }
    return "";
  }

  public get title(): string {
    if (this.session.user.userType) {
      return this.session.getUserTitle(this.user.userType);
    }
    return "";
  }

  ROUTES: RouteInfo[] = [];

  generateSidebarItems() {
    if (Number(this.session.user.userType) === UserType.Admin) {
      this.ROUTES = [
        {
          path: "/auth/admin/dashboard",
          title: "Admin Dashboard",
          icon: "icofont-dashboard-web",
          class: "",
        },
        {
          path: "/auth/profile",
          title: "Profile",
          icon: "icofont-ui-user",
          class: "",
        },
        {
          path: "/auth/admin/email-management",
          title: "Email Settings",
          icon: "icofont-ui-settings",
          class: "",
        },
        {
          path: "/admin",
          title: "Logout",
          icon: "icofont-logout",
          class: "",
        },
      ];
    } else {
      this.ROUTES = [
        {
          path: "/auth/user-dashboard",
          title: "Dashboard",
          icon: "icofont-dashboard-web",
          class: "",
        },
        {
          path: "/auth/profile",
          title: "Profile",
          icon: "icofont-ui-user",
          class: "",
        },
        {
          path: this.appRoute,
          title: this.title,
          icon: "icofont-list",
          class: "",
        },
        {
          path: "/auth/search/favorite",
          title: "Favorite",
          icon: "icofont-favourite",
          class: "",
        },
        {
          path: "/login",
          title: "Logout",
          icon: "icofont-logout",
          class: "",
        },
      ];
    }
  }

  ngOnInit() {
    this.listTitles = this.ROUTES.filter((listTitle) => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName("navbar-toggle")[0];
    this.router.events.subscribe((event) => {
      this.sidebarClose();
    });
  }
  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }
  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    setTimeout(function () {
      toggleButton.classList.add("toggled");
    }, 500);

    html.classList.add("nav-open");
    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    if (window.innerWidth < 991) {
      setTimeout(function () {
        if (mainPanel) {
          mainPanel.style.position = "";
        }
      }, 500);
    }
    this.toggleButton.classList.remove("toggled");
    this.sidebarVisible = false;
    html.classList.remove("nav-open");
  }
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName("nav")[0];
    if (!this.isCollapsed) {
      navbar.classList.remove("navbar-transparent");
      navbar.classList.add("bg-white");
    } else {
      navbar.classList.add("navbar-transparent");
      navbar.classList.remove("bg-white");
    }
  }
}
