<div class="banner-area">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="about-area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="about-item">
                <div class="about-left">
                  <img src="assets/img/home/12.png" alt="About" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="home-item about-right">
                <div class="banner-item">
                  <h1>When it takes more than two people to make a baby.</h1>
                  <p>
                    The National Exchange for Egg Donation and Surrogacy
                    Infertility no longer needs to be a barrier to having a baby
                    of your own. Today, collaborative reproduction – becoming a
                    parent with the help of egg donation or surrogacy – gives
                    you more options and more hope than ever before.
                  </p>
                  <div class="common-btn-two">
                    <a routerLink="/signup">SIGN UP</a>
                    <a class="cmn-btn-right-two" routerLink="/login">LOG IN</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="heart-shape">
    <img src="assets/img/home-three/3.png" alt="Banner" />
  </div>
</div>

<section class="speciality-area pb-70">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-12">
        <div class="speciality-left">
          <div class="speciality-item">
            <div class="row m-0">
              <div
                class="col-sm-4 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <div routerLink="/aspiring-parents" class="speciality-inner">
                  <i class="icofont-check-circled"></i>
                  <h3>Become a parent</h3>
                  <hr />
                  <p>
                    Aspiring parents struggling with infertility who want to
                    experience the rewards of pregnancy and childbirth have
                    exciting options.
                  </p>
                  <a routerLink="/aspiring-parents">Learn More</a>
                </div>
              </div>

              <div
                class="col-sm-4 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".5s"
              >
                <div routerLink="/donor" class="speciality-inner">
                  <i class="icofont-check-circled"></i>
                  <h3>Become an egg donor</h3>
                  <hr />
                  <p>
                    There are few gifts more precious than the donation of an
                    egg that helps aspiring parents fulfill their dream of
                    having a baby.
                  </p>
                  <a routerLink="/donor">Learn More</a>
                </div>
              </div>

              <div
                routerLink="/surrogate"
                class="col-sm-4 col-lg-4 wow animate__animated animate__fadeInUp"
                data-wow-delay=".3s"
              >
                <div class="speciality-inner">
                  <i class="icofont-check-circled"></i>
                  <h3>Become a surrogate</h3>
                  <hr />
                  <p>
                    As a surrogate carrier, you experience an enormous emotional
                    reward through helping infertile couples become parents.
                  </p>
                  <a routerLink="/surrogate">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="speciality-area pb-70">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-7">
        <div class="speciality-left">
          <div class="section-title-two">
            <h2>NEEDS</h2>
          </div>
          <div class="hospital-item">
            <div class="row m-0">
              <hr />
              <p>
                The National Exchange for Egg Donation and Surrogacy – can help
                you:
              </p>
              <ul>
                <li>
                  <i class="icofont-check-circled"></i>
                  Select your ideal
                  <a routerLink="/egg-donation">egg donor</a> from the privacy
                  of your home using our comprehensive online database of
                  pre-screened donors
                </li>
                <li>
                  <i class="icofont-check-circled"></i>
                  Locate a compassionate, trustworthy
                  <a routerLink="/surrogacy">surrogate carrier</a>
                </li>
                <li>
                  <i class="icofont-check-circled"></i>
                  Find the medical, legal, and other
                  <a routerLink="/resources">resources</a> you need from our
                  extensive community of experienced service providers
                </li>
              </ul>
              <p>
                Since 1992, hundreds of hopeful parents have turned to NEEDS
                (formerly NESPA) as their trusted, knowledgeable guide through
                the emotional, sometimes daunting process of collaborative
                reproduction. NEEDS is there for you when it takes more than two
                people to make a baby.
              </p>
              <a class="hospital-btn text-center" routerLink="/about"
                >Learn More</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 pr-0">
        <div class="speciality-item speciality-right">
          <img src="assets/img/home/4.png" alt="Speciality" />
        </div>
      </div>
    </div>
  </div>
</section>

<section class="welcome-area pb-70">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-6 p-0 bp-100">
        <div class="welcome-item welcome-right">
          <div class="section-title-two">
            <h2>ABOUT</h2>
          </div>
          <ul>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <i class="icofont-prescription"></i>
              <div class="welcome-inner">
                <h3>Our Process</h3>
                <p>
                  NEEDS serves as a collaborative community that brings aspiring
                  parents together with the egg donors..
                  <a routerLink="/about">learn more</a>
                </p>
              </div>
            </li>

            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <i class="icofont-dna-alt-1"></i>
              <div class="welcome-inner">
                <h3>Our mission</h3>
                <p>
                  Since 1992, The National Exchange for Egg Donation and
                  Surrogacy has been a leading pioneer in assisting aspiring
                  parents .. <a routerLink="/about">learn more</a>
                </p>
              </div>
            </li>

            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".7s"
            >
              <i class="icofont-badge"></i>
              <div class="welcome-inner">
                <h3>Privacy Policy</h3>
                <p>
                  The National Exchange for Egg Donation and Surrogacy, LLC., is
                  firmly committed to protecting the privacy of aspiring
                  parents.. <a routerLink="/about">learn more</a>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-6 p-0">
        <div class="welcome-item pb-70">
          <img src="assets/img/home/1.png" alt="image" />
        </div>
      </div>
    </div>
  </div>
</section>

<div class="video-wrap video-wrap-two">
  <div class="container-fluid p-0">
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div class="section-title-two">
          <h3>Become a parent</h3>
          <hr />
          <p>
            Aspiring parents struggling with infertility who want to experience
            the rewards of pregnancy and childbirth have exciting options.
          </p>
        </div>
        <div class="video-area">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <!-- <div class="video-item">
                  <a
                    href="http://www.youtube.com/watch?v=0O2aH4XLbto"
                    class="popup-youtube"
                    ><i class="icofont-ui-play"></i
                  ></a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-doctor"
        role="tabpanel"
        aria-labelledby="pills-doctor-tab"
      >
        <div class="video-area">
          <div class="d-table">
            <div class="d-table-cell">
              <div class="container">
                <div class="video-item">
                  <a
                    href="http://www.youtube.com/watch?v=0O2aH4XLbto"
                    class="popup-youtube"
                    ><i class="icofont-ui-play"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="pt-70">
  <app-story-lookup></app-story-lookup>
</div>

<!-- <div class="counter-area counter-area-three pb-70">
  <div class="container">
    <div class="row counter-bg">
      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="icofont-patient-bed"></i>
          <h3 class="counter">850</h3>
          <p>ASPIRING PARENTS</p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="icofont-people"></i>
          <h3><span class="counter">25000</span>+</h3>
          <p>EGG DONATION</p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="icofont-doctor-alt"></i>
          <h3 class="counter">750</h3>
          <p>SURROGACY</p>
        </div>
      </div>

      <div class="col-sm-6 col-lg-3">
        <div class="counter-item">
          <i class="icofont-badge"></i>
          <h3 class="counter">18</h3>
          <p>Year Experience</p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<!-- <section class="blog-area pt-100 pb-70">
  <div class="container">
    <div class="section-title-two">
      <h2>Our latest blogs</h2>
    </div>

    <div class="row">
      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="blog-item">
          <div class="blog-top">
            <a routerLink="/blog-details"
              ><img src="assets/img/home/3.png" alt="Blog"
            /></a>
          </div>

          <div class="blog-bottom">
            <h3>
              <a routerLink="/blog-details"
                >Lorem ipsum is dolor sit amet, csectetur.</a
              >
            </h3>
            <p>
              Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore
              smod tempor incididunt ut labore et....
            </p>
            <ul>
              <li>
                <a routerLink="/blog-details"
                  >Read More <i class="icofont-long-arrow-right"></i
                ></a>
              </li>
              <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="blog-item">
          <div class="blog-top">
            <a routerLink="/blog-details"
              ><img src="assets/img/home/4.png" alt="Blog"
            /></a>
          </div>

          <div class="blog-bottom">
            <h3>
              <a routerLink="/blog-details"
                >Lorem ipsum is dolor sit amet, csectetur.</a
              >
            </h3>
            <p>
              Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore
              smod tempor incididunt ut labore et....
            </p>
            <ul>
              <li>
                <a routerLink="/blog-details"
                  >Read More <i class="icofont-long-arrow-right"></i
                ></a>
              </li>
              <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
            </ul>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="blog-item">
          <div class="blog-top">
            <a routerLink="/blog-details"
              ><img src="assets/img/home/5.png" alt="Blog"
            /></a>
          </div>

          <div class="blog-bottom">
            <h3>
              <a routerLink="/blog-details"
                >Lorem ipsum is dolor sit amet, csectetur.</a
              >
            </h3>
            <p>
              Lorem ipsum is dolor sit amet, csectetur adipiscing elit, dolore
              smod tempor incididunt ut labore et....
            </p>
            <ul>
              <li>
                <a routerLink="/blog-details"
                  >Read More <i class="icofont-long-arrow-right"></i
                ></a>
              </li>
              <li><i class="icofont-calendar"></i> Jan 03, 2023</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
