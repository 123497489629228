<div class="page-title-area page-title-donor">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>EGG DONATION</h2>
      </div>
    </div>
  </div>
</div>

<div class="service-details-area ptb-100">
  <div class="container ptb-100">
    <div class="services-details-img">
      <p>
        There are few gifts more precious than the donation of an egg that helps
        aspiring parents fulfill their dream of having a baby. If you would like
        to become one of the anonymous egg donors affiliated with NEEDS, you
        must be a healthy, drug-free woman between the ages of 20 and 31. It’s
        also important to know that, if selected as a donor, you must be willing
        to undergo appropriate medical and psychological testing and take
        fertility drugs as required for the egg donation process.
      </p>

      <blockquote>
        Egg donors selected by aspiring parents to donate through NEEDS will be
        compensated $6,000 for their time and effort.
      </blockquote>

      <p>
        If you’d like to learn more about our egg donation program, this section
        provides an in-depth overview of the NEEDS egg donor program and the
        step-by-step process for joining our community of anonymous donors to
        help aspiring parents fulfill their dream of having a child.
      </p>

      <blockquote>
        <a routerLink="/become-donor">Click here</a> for a Step-by-Step Guide to
        becoming an Egg Donor.
      </blockquote>
    </div>
  </div>
</div>
