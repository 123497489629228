import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-home-one",
  templateUrl: "./home-one.component.html",
  styleUrls: ["./home-one.component.scss"],
})
export class HomeOneComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
