import { Directive } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { map, take } from "rxjs/operators";

@Directive()
export abstract class BaseLayout {
  public currentTime = new Date();
  public dataRoute: any;

  constructor(protected routes?: ActivatedRoute) {}

  protected async getParams(): Promise<Params> {
    return this.routes.params
      .pipe(
        map((r) => r),
        take(1),
      )
      .toPromise();
  }

  protected async getQueryParams(): Promise<Params> {
    return this.routes.queryParams
      .pipe(
        map((r) => r),
        take(1),
      )
      .toPromise();
  }

  protected UserType(type: number): string {
    switch (type) {
      case 1:
        return "Egg Donor";
      case 2:
        return "Surrogate";
      case 3:
        "Aspiring Parents";
      default:
        return "not set";
    }
  }

  protected Status(type: number): string {
    switch (type) {
      case 0:
        return "In Process";
      case 1:
        return "Approved";
      case 3:
        "Declined";
      default:
        return "Pending";
    }
  }

  protected getRouteData(): any {
    this.routes.data.subscribe((val) => {
      this.dataRoute = val;
    });
    return this.dataRoute;
  }
}
