<div class="header-top">
  <div class="container">
    <div class="row align-items-center">
      <div
        class="card p-1 text-center text-white welcome-message"
        style="background-color: rgb(234, 102, 122)"
      >
        <b>
          Welcome to our new website! Please be patient with us while we move
          our user accounts from our old website., and put in a few finishing
          touches.
        </b>
      </div>

      <div class="col-sm-9 col-lg-9">
        <div class="header-top-item">
          <div class="header-top-left">
            <ul>
              <li>
                <a href="tel:+07554332322"
                  ><i class="icofont-ui-call"></i> Call : 1(781)-353-1300</a
                >
              </li>
              <li>
                <a href="mailto:info@fertilityneeds.com"
                  ><i class="icofont-ui-message"></i> info@fertilityneeds.com</a
                >
              </li>
              <li>
                <i class="icofont-location-pin"></i>
                USA
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-sm-3 col-lg-3">
        <div class="header-top-item">
          <div class="header-top-right">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/fertilityneeds"
                  target="_blank"
                  ><i class="icofont-facebook"></i
                ></a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/fertilityneeds/"
                  target="_blank"
                  ><i class="icofont-instagram"></i
                ></a>
              </li>
              <!-- <li>
                <a href="https://twitter.com/#!/fertilityneeds" target="_blank"
                  ><i class="icofont-twitter"></i
                ></a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="navbar-area sticky-top">
  <div class="mobile-nav">
    <a routerLink="/" class="logo"
      ><img src="assets/img/logo-two.png" alt="Logo"
    /></a>
  </div>

  <div class="main-nav">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light">
        <a class="navbar-brand" routerLink="/"
          ><img src="assets/img/logo-two.png" alt="Logo"
        /></a>
        <div class="d-flex flex-column">
          <a routerLink="/" class="nav-item nav-item-needs h3">N.E.E.D.S.</a>
          <a routerLink="/" class="nav-item nav-item-needs h6"
            >National Exchange for Egg Donation and Surrogacy</a
          >
        </div>
        <div
          class="collapse navbar-collapse mean-menu"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav">
            <li class="nav-item">
              <a
                routerLink="/"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >HOME</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/about"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >ABOUT</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/faq"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >FAQs</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/resources"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >RESOURCES</a
              >
            </li>

            <li class="nav-item">
              <a
                routerLink="/contact"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >CONTACTS</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/signup"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Sign up</a
              >
            </li>
            <li class="nav-item">
              <a
                routerLink="/login"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                >Login</a
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>
