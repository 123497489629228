import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SessionService } from "../../guards/session.service";
import { UserType } from "../../layouts/models/usertype";
import { UserService } from "../../service/user/user.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent {
  constructor(
    private fb: FormBuilder,
    private service: UserService,
    private auth: SessionService,
    private messageService: MessageService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.auth.clearSession();
  }

  errorMessage: string;
  success: boolean = false;

  public form: FormGroup = this.fb.group({
    email: new FormControl("", [Validators.required]),
    // password: new FormControl("", [Validators.required]),
    // confirm: new FormControl("", [Validators.required]),
  });

  async submit() {
    if (this.form.valid) {
      const result = await this.service.requestResetPassword(this.form.value);
      if (result) {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: "We sent you an email!",
        });
        this.success = true;
      } else {
        this.errorMessage = this.service.errorMessage;
      }
    }
  }
}
