<div class="page-title-area page-title-surrogate">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>SURROGACY</h2>
      </div>
    </div>
  </div>
</div>

<div class="service-details-area ptb-100">
  <div class="container">
    <h3>BECOMING A PARENT VIA SURROGATE GESTATIONAL CARRIER</h3>
    <hr />
    <div class="services-details-img">
      <p>
        Dr. Angela Figueroa, NEEDS’ founder (retired), became a parent herself
        with the help of a surrogate. Since 1992, numerous NEEDS clients have
        been able to become parents through a surrogate carrier. This section
        provides an in-depth overview of the NEEDS Surrogat
      </p>

      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">
              Fees and Estimated Costs for Gestational Surrogacy
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><b>SURROGACY</b> Program Fee</td>
            <td>$10,500 US Residents/$12,000 Foreign Residents</td>
          </tr>
          <tr>
            <td>Surrogate’s Fee (established by surrogate candidate)</td>
            <td>Starting at $35,000 singleton, $45,000 twins</td>
          </tr>
          <tr>
            <td>Life Insurance</td>
            <td>$350 est for $300,000 term policy</td>
          </tr>
          <tr>
            <td>Maternity Clothing Allowance</td>
            <td>$750</td>
          </tr>
          <tr>
            <td>Travel Allowance (if applicable for out of area surrogate)</td>
            <td>
              All related expenses for airfare, ground transportation, $75 per
              diem and hotel
            </td>
          </tr>
          <tr>
            <td>Attorney Fee for Contract Review</td>
            <td>est $1500</td>
          </tr>
          <tr>
            <td>Medical costs for hospital/delivery</td>
            <td>Varies</td>
          </tr>
          <tr>
            <td>
              Contract Attorney Fees – includes escrow account management and
              legal fees for parentage rights
            </td>
            <td>$3000 - $5000</td>
          </tr>
          <tr>
            <td>
              Psychologist Fee – Meetings with Intended Parents, Carrier and her
              partner, and all written reports
            </td>
            <td>
              $1200 - $2000 depending upon pricing of chosen social worker
            </td>
          </tr>
          <tr>
            <td>
              Other possible costs:<br />
              <ul>
                <li>
                  Lost wages for surrogate Child care/housekeeping if carrier is
                  confined to bed
                </li>
                <li>Health Insurance for pre-natal care and delivery</li>
              </ul>
            </td>
            <td>Varies</td>
          </tr>
          <tr>
            <td>
              <p>TOTAL COSTS FOR A SUCCESSFUL IVF/ET SURROGACY CAN BE OVER</p>
            </td>
            <td>
              <p>$50,000 to $100,000</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h5>
        A STEP-BY-STEP GUIDE TO BECOMING A PARENT VIA A SURROGATE GESTATIONAL
        CARRIER
      </h5>
      <p>
        The process of working with NEEDS to find a suitable surrogate and
        becoming a parent via gestational surrogacy is straightforward.
        Following are the eight steps involved.
      </p>

      <ul>
        <li>1. Register for a NEEDS Online Account</li>
        <li>2. Surrogate Search & Selection</li>
        <li>3. Surrogate Confirmation</li>
        <li>4. Introduction to Surrogate</li>
        <li>5. Agreements & NEEDS Fee</li>
        <li>6. Surrogate Psychological Evaluation</li>
        <li>7. Surrogate's Fee Negotiation & Legal Contract</li>
        <li>8. Pregnancy</li>
      </ul>

      <h3>1. Register for a NEEDS Online Account</h3>
      <blockquote>
        First, you complete the intended parent registration form online. You
        immediately will be provided a secure access to our surrogate database.
      </blockquote>

      <h3>2. Surrogate Search & Selection</h3>
      <p>
        With your username and password, you then can search our online database
        of all available surrogates to locate a surrogate that meets your
        desired characteristics. You can organize your search using search
        criteria to limit the results to those surrogates most closely matching
        your requirements. NEEDS can also conduct a custom surrogate search on
        your behalf based on your ideal criteria for a surrogate gestational
        carrier.
      </p>

      <blockquote>
        You are provided extensive background information with which to make
        your surrogate selection. NEEDS provides pictures of the surrogates and
        an extensive list of personal characteristics. Moreover, an in-depth
        medical and personal disclosure about each surrogate is provided, based
        directly on the information the surrogate has provided as part of the
        NEEDS surrogate registration and pre-screening process. (In other words,
        NEEDS does nothing to "package" its surrogates - we want you to get to
        know the real person through our selection process.)
      </blockquote>

      <h3>3. Surrogate Confirmation</h3>
      <p>
        Once you have selected online one or more potential surrogates, and
        ranked them according to preference, NEEDS will notify your first
        surrogate choice to confirm her interest and availability as a carrier.
        Your biographies will be provided to her for her review, and she may ask
        additional questions about you. You then will be contacted to confirm
        your surrogate selection, and the surrogate will be "placed on hold"
        (i.e., will not be available to other aspiring parents).
      </p>

      <h3>4. Introduction to Surrogate</h3>
      <blockquote>
        Once you have selected your choice of a surrogate carrier, NEEDS will
        then schedule a telephone conference call with you (the aspiring
        parents) and the surrogate. If the initial telephone meeting is
        successful - if you and the surrogate remain interested in proceeding
        with the process - then you will be asked to send in the signed
        agreements and fee.
      </blockquote>

      <h3>5. Agreements & NEEDS Fee</h3>
      <p>
        Once you've finalized your selection of a surrogate, you will print out
        and sign NEEDS'
        <a
          (click)="openFile('ParentSurrogateAgreement')"
          routerLink="/surrogacy"
          >Surrogate Gestational Carrier Agreement</a
        >
        and
        <a
          (click)="openFile('ParentSurrogateAcknowledgement')"
          routerLink="/surrogacy"
        >
          Acknowledgement of Intended Parents</a
        >
        documents along with a check for the balance of the NEEDS' fee. We
        request that you return the agreements and payment within three (3)
        business days of your selection. The purpose of this request is to
        ensure fairness to the surrogate candidate and to other aspiring parents
        who may be interested in her as a gestational carrier. Once the
        agreements and fee are received by NEEDS, the identifying information on
        both parties will be released. NEEDS will then ask that you schedule an
        in-person meeting with the surrogate to further get to know each other.
      </p>

      <h3>6. Surrogate Psychological Evaluation</h3>
      <blockquote>
        Once you agree to work together, the surrogate will be asked to meet
        with a licensed psychologist for an assessment of her mental and
        emotional readiness for the surrogacy process. This evaluation will be
        paid for by you, the aspiring parents.
      </blockquote>

      <h3>7. Surrogate's Fee Negotiation & Legal Contract</h3>
      <p>
        The fee for the service of carrying and delivering your biological baby
        is determined by the surrogate gestational carrier. Although being a
        surrogate is a priceless gift, most surrogates request - and most
        aspiring parents expect to compensate - $25,000 for a single birth and
        $30,000 for twins, in addition to expenses. Once you agree upon a fee
        with the surrogate, you and your lawyer will present a contract to the
        surrogate. The surrogate will then meet with an attorney of her choice,
        paid for by the aspiring parents, who will review the contract with her
        and negotiate any changes with you before all parties sign.
      </p>

      <p>
        Note that the legal contract involves you and the surrogate - not NEEDS.
        However, NEEDS will be involved and available as an advisor to the
        intended parents and the surrogate throughout the gestational surrogacy
        process.
      </p>

      <h3>8. Pregnancy</h3>
      <blockquote>
        The medical procedure for the surrogate gestational carrier is similar
        to having a pap smear and in most cases is pain-free. The surrogate's
        menstrual cycle and the menstrual cycle of the biological mother will be
        synchronized using daily injections of Lupron. The surrogate will also
        be taking Estrogen prior to ovulation, and the biological mother will be
        taking daily injections of fertility drugs. The development of the
        biological mother's eggs will be monitored by daily blood work and
        ultrasounds.
      </blockquote>

      <p>
        The thickness of the lining of the surrogate's uterus will be evaluated
        using blood tests and ultrasounds. Eggs are then retrieved from the
        biological mother (the procedure is non-surgical and generally requires
        only a mild sedative to relax the mother). Three days after the eggs
        have been retrieved and fertilized with the sperm of the biological
        father, the physician will transfer the embryos to the surrogate. The
        procedure is completed under sterile conditions. Depending on the
        physician, the surrogate may then begin progesterone injections.
      </p>

      <blockquote>
        A pregnancy test will be taken in approximately 10 days after the
        transfer of embryos to you. Once pregnant, you will carry the aspiring
        parents' baby (or babies) to term, maintaining a close, personal
        relationship with the parents throughout the process until delivery.
      </blockquote>

      <div class="blog-bottom">
        <a routerLink="/signup">Sign Up</a>
      </div>
    </div>
  </div>
</div>
