import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  RequiredValidator,
  Validators,
} from "@angular/forms";
import { LeadService } from "../../service/leads/lead.service";
import { MessageService } from "primeng/api";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  providers: [LeadService],
})
export class FooterComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private service: LeadService,
  ) {}
  currentYear: number;
  message: string;
  messageFeedback: string;

  ngOnInit(): void {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  public form: FormGroup = this.fb.group({
    email: ["", Validators.required],
    type: ["newsletter"],
  });

  public formFeedback: FormGroup = this.fb.group({
    email: ["", Validators.required],
    name: ["", Validators.required],
    message: [""],
  });

  async submit() {
    if (this.form.valid) {
      const response = await this.service.create(this.form.value);
      if (response) {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: `Congratulations! 🎉 You're Now Part of Our Exclusive Community!`,
        });
        this.message = `Congratulations! 🎉 You're Now Part of Our Exclusive Community!`;
        this.form.disable();
      } else {
        this.message = this.service.errorMessage;
      }
    }
  }

  async feedback() {
    if (this.formFeedback.valid) {
      const response = await this.service.createFeedback(
        this.formFeedback.value,
      );
      if (response) {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: `Your feedback has been submit, thank you! 🎉`,
        });
        this.messageFeedback = `Your feedback has been submit, thank you! 🎉`;
        this.formFeedback.disable();
      } else {
        this.message = this.service.errorMessage;
      }
    }
  }
}
