<nav
  class="navbar navbar-expand-lg navbar-absolute fixed-top navbar-transparent"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-toggle">
        <button class="navbar-toggler" (click)="sidebarToggle()">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="javascript:void(0)">{{ getTitle() }}</a>
    </div>
    <div
      class="collapse navbar-collapse justify-content-end"
      id="collapseExample"
      [ngbCollapse]="isCollapsed"
    ></div>
  </div>
</nav>
