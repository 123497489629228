import { HttpClient, HttpHeaders, HttpEvent } from "@angular/common/http";
import { SessionStorage } from "ngx-webstorage";
import { of as observableOf, Observable } from "rxjs";
import { catchError, map, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserType } from "../layouts/models/usertype";
import { ApiResult } from "../layouts/models/apiResult";

export abstract class ApiService {
  constructor(private http?: HttpClient) {}

  protected _errorMessage: string;
  public get errorMessage(): string {
    return this._errorMessage;
  }

  @SessionStorage()
  token: string;

  @SessionStorage()
  protected userType: UserType;

  @SessionStorage()
  protected locationId: number;

  private buildResponse<T>(res: any): ApiResult<T> {
    const output = res.body as unknown as ApiResult<T>;
    const resToken = res.headers.get("X-Auth-Token");
    if (resToken && output.authToken !== resToken) {
      output.authToken = resToken;
    }
    return output;
  }

  private buildHeaders(requireAuth = true, isMultiPart = false): any {
    this._errorMessage = "";
    let headers = new HttpHeaders();

    if (requireAuth && this.token) {
      headers = headers.append("Authorization", `Bearer ${this.token}`);
    }

    if (!isMultiPart) {
      headers = headers.append("Content-Type", "application/json");
    }
    if (this.locationId) {
      headers = headers.append("locationId", this.locationId?.toString());
    }

    if (this.userType) {
      headers = headers.append("UserType", this.userType.toString());
    }

    return { headers, observe: "response" };
  }

  private errorResponse<T>(err: any): ApiResult<T> {
    return {
      code: 500,
      message: err.message,
      data: err,
    } as unknown as ApiResult<T>;
  }

  private async parseResponse<T>(
    res: Observable<HttpEvent<T>>,
  ): Promise<ApiResult<T>> {
    const data = await res
      .pipe(
        map((r) => this.buildResponse<T>(r)),
        catchError((err) => observableOf(this.errorResponse<T>(err))),
        take(1),
      )
      .toPromise();
    if (data) {
      if (data.authToken) {
        this.token = data.authToken;
      }
      if (!data.status && data.message) {
        this._errorMessage = data.message;
        this.token = null;
      }
    }
    return data;
  }

  protected async getResponse<T>(
    route: string,
    requireAuth = true,
  ): Promise<ApiResult<T>> {
    const uri = `${environment.api}/${route}`;
    return this.parseResponse<T>(
      this.http.get<T>(uri, this.buildHeaders(requireAuth)),
    );
  }

  protected async postResponse<T>(
    route: string,
    data?: unknown,
    requireAuth = true,
  ): Promise<ApiResult<T>> {
    const uri = `${environment.api}/${route}`;
    return this.parseResponse<T>(
      this.http.post<T>(
        uri,
        JSON.stringify(data),
        this.buildHeaders(requireAuth),
      ),
    );
  }

  protected async postMultiDataResponse<T>(
    route: string,
    data: any,
    requireAuth = true,
  ): Promise<ApiResult<T>> {
    const uri = `${environment.api}/${route}`;
    return this.parseResponse<T>(
      this.http.post<T>(uri, data, this.buildHeaders(requireAuth, true)),
    );
  }

  protected async putResponse<T>(
    route: string,
    data?: unknown,
    requireAuth = true,
  ): Promise<ApiResult<T>> {
    const uri = `${environment.api}/${route}`;
    return this.parseResponse<T>(
      this.http.put<T>(
        uri,
        JSON.stringify(data),
        this.buildHeaders(requireAuth),
      ),
    );
  }

  protected async deleteResponse<T>(
    route: string,
    requireAuth = true,
  ): Promise<ApiResult<T>> {
    const uri = `${environment.api}/${route}`;
    return this.parseResponse<T>(
      this.http.delete<T>(uri, this.buildHeaders(requireAuth)),
    );
  }
}
