<div class="page-title-area page-title-sucess">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>SUCCESS STORY</h2>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 1" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>JOY'S STORY</h2>
      <p>
        I have had the opportunity to be a gestational surrogate on two
        occasions. It was a positive life experience. I feel I have played the
        roles of both pioneer and teacher. The many people that I encountered in
        my day to day life and learned I was a surrogate were most interested
        and had many questions. I enjoyed answering their questions and felt it
        was a way to "educate" people to the positive aspects of surrogacy as
        sometimes the media only portrays the negative side. Becoming a
        surrogate was something I had wanted to do for a long time, having felt
        so fortunate of being able to become pregnant and give birth I felt a
        great deal of empathy towards those who could not. It is not something
        to be entered into lightly. The decision requires a strong commitment
        and does affect the entire family.
      </p>

      <blockquote>
        <i class="icofont-quote-left"></i>
        I was so fortunate to have the opportunity to work with Dr. Angela
        Figueroa. Her expertise and experience helped me through the process of
        getting started on my journey. My husband and I immediately felt
        comfortable with her. Angela was always available to answer questions or
        provide advice even during the pregnancy. She is a great listener and
        has always been there for me.
      </blockquote>

      <p>
        In March of 2002, I gave birth via a C section to twin girls for a
        wonderful couple and then in March of 2004, after being matched with
        another great couple, I gave birth to a boy. During the course of each
        pregnancy in which both couples were very much involved we developed a
        strong long lasting friendship. We are still a part of each others
        lives. My own children and husband became very close with these families
        also. My children were never affected emotionally by me having to give
        up the babies. That was frequently a concern by many people as to how my
        children dealt with me being a surrogate.
      </p>
      <p>
        My children honestly think that this is something that all mothers
        usually do for women who cannot have babies on their own. The
        pregnancies and births were experiences that I will never forget.
      </p>
      <p>
        It is a most fulfilling reward to be able to give a childless couple the
        opportunity to be called mommy and daddy.
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss1.png" alt="Service" />
            </div>
            <p>-Joy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 2" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>CHRIS PAYNE'S STORY</h2>
      <p>
        I guess we chose surrogacy under different circumstances than most. Six
        years ago I was diagnosed with end stage renal disease, and would need a
        kidney transplant to lead a normal life. It is not an easy request to
        ask a loved one to donate a kidney, but luckily I didn't have to ask as
        my father insisted that he be the one. Through all of this my husband
        and I had always hoped for a family, and while it didn't look to good at
        the outset, once my transplant had taken I was given the okay to try and
        get pregnant. I did get pregnant and the pregnancy went well until the
        32nd week when I became pre-enclamptic and required an early delivery to
        keep both the baby and me healthy. Our daughter was born pre-mature, but
        very robust and continued to develop beautifully. I, however, did not
        fare as well, and began to have kidney problems again. W hile we were
        thrilled to have a baby daughter it didn't look as if I could have any
        more children. This saddened me because I had grown up with two sisters,
        and their friendship has meant the world to me.
      </p>

      <blockquote>
        <i class="icofont-quote-left"></i>
        My husband suggested that we consider a gestational surrogate since we
        could still conceive a child, but I couldn't be the one to carry it. He
        came to the decision more easily than I did, as it I took me more than a
        year until I was ready to talk with potential surrogates. Since I had
        experienced childbirth before I knew what sort of emotional bond I was
        giving up, and it scared me a little to think that I would be putting my
        most precious possession in the hands of someone I just met. In the end
        I decided that the risk was worth it. To have another child meant more
        to me than the emotional strain it might cause. As I look back, this all
        seems absurd, considering the wonderful person who in the end brought
        our second child into the world.
      </blockquote>
      <p>
        Dr. Figueroa introduced us to Maria and her husband, and I immediately
        felt at ease with them. They were very down to earth, genuine people who
        appeared to have their hearts in the right place. We all agreed to work
        together and began the process of preparing for in vitro fertilization.
        We were very nervous until it was confirmed that Maria was pregnant, but
        even with that good news the road ahead was a little bumpy. Maria's
        first few months were full of morning sickness and dehydration. It made
        us very uneasy to know that we had put her in this predicament. Maria
        was such a trooper and never complained, it was just part of life and
        she moved on. Maria's husband and children were wonderful throughout her
        entire pregnancy. They provided support and understanding that allowed
        her to deal with the ups and downs as they came along. It was reassuring
        to know that she didn't regret her decision and would deal with whatever
        happened. Of course, in the end she delivered a beautiful baby girl and
        we were all happy and relieved that everything had gone so well. Through
        this process I had gained more than a child, but a friend for life.
      </p>
      <p>
        I have been blessed by two very dear people in my life - my father for
        donating a kidney and Maria for helping us create our family. They have
        both done things that go above and beyond what most people will do for
        each other. We felt it was only fitting that we honor Maria in some way,
        and thus we named our daughter Trinity Maria. Trinity - because it took
        three of us to create her, and Maria as a tribute to her generosity. We
        also felt that we should in some way mention our gratitude to Maria when
        we told the world about our new daughter, and thus Trinity's birth
        announcement reflected her contribution as well.
      </p>

      <p>
        Our experience has been such a positive one that I tell those that ask
        that if I couldn't be the one to give birth to my daughter then I'm glad
        it was Maria.
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss2.png" alt="Service" />
            </div>
            <p>-Chris Payne</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 3" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>LISA'S STORY</h2>
      <p>
        My husband and I met Joy and her family through Fertility Needs on March
        3, 2003 and began our journey to become parents through gestational
        surrogacy. We became pregnant with one healthy baby on the first IVF
        transfer (coincidentally on my birthday - what a present!). During the
        pregnancy, our relationship with Joy and her family exceeded our
        expectations. We shared an open, caring relationship with an unspoken
        trust for each other. The bond that forms when someone is carrying your
        child is indescribable.
      </p>

      <blockquote>
        <i class="icofont-quote-left"></i>
        Our two families enjoyed several outings together including trips to New
        York City, Boston and several overnight sleepovers at our house with her
        two kids. We also attended her doctor appointments and were able to
        fully experience the pregnancy through Joy, something that was very
        important to us since we were not able to carry a child on our own.
      </blockquote>
      <p>
        On March 2, 2004, one year from the day we met, Joy delivered a
        beautiful, healthy baby boy to his parents. We are overjoyed to finally
        have our own child. Our experience with surrogacy was such an amazing
        one that we are proud to share our story and perhaps help others become
        parents...
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss3.png" alt="Service" />
            </div>
            <p>-Lisa</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 4" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>TRACY'S STORY</h2>
      <p>
        Ever since I was a junior in high school, I wanted to be a surrogate.
        That is when I learned about surrogacy because a friend of mine did a
        project on it in Anatomy class. I knew that once my family was complete,
        I wanted to help someone else. I got married and had 2 beautiful
        children. My husband and I knew that our family was now complete so we
        discuss surrogacy.
      </p>
      <p>
        I met with Angela in January of 2003; she was very nice and was sure she
        would be able to help me find a very nice couple to help. She was right
        because within a week, I was matched up with my perfect couple. We met
        at Angela¹s office in March of 2003 and we really hit it off. Angela
        went over with us all the various "issues" we would come across to make
        sure we were all on the same page as to what we all expected and wanted
        to get out of a surrogacy relationship.
      </p>

      <blockquote>
        <i class="icofont-quote-left"></i>
        We went through all the medical screening and pre-testing that was
        necessary and was finished with all that by the middle of May. By the
        middle of June, I was starting fertility meds. The transfer was on July
        17, 2003. I went in 12 days later for a pregnancy test. It was positive!
        The couple and I were very happy that it took on the first try.
      </blockquote>
      <p>
        For the most part, my pregnancy was uneventful. The biggest thing that
        happened was my couple and I became very close. We were talking to each
        other at least 2 times a week, sometimes for an hour or 2 at a time.
        Towards the end, things started to happen. My blood pressure was rising,
        and the baby was getting big! I was ordered to stop working by 36 weeks.
        That helped the blood pressure. We didn't think the baby's weight would
        end up leading to a C-section, but on March 27, 2004; my couple's baby
        girl came into this world via C-section weighing almost 9 lbs! We all
        cried tears of joy. It was a very emotional day and I will never forget
        it.
      </p>

      <p>
        I saw the baby that was inside me for 9 months and it was just amazing!
        To see my couple holding their little girl that they waited so long to
        hold...well... I can only say that it was a very rewarding and
        fulfilling experience. Not once did I have any sad feelings or regrets,
        after all, it was never my baby. This beautiful perfect girl was created
        to be loved by my couple, not out of the love of my husband and me. Now
        that I am recovering from my first surrogacy journey, I feel so good
        about myself. I have always had low self-esteem, but giving someone the
        gift of a child made me feel so very important and has totally lifted my
        self worth. I know now that even if I never do anything else with my
        life, I made a difference in the life of another.
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss4.png" alt="Service" />
            </div>
            <p>-Tracy</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 5" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>MARIA'S STORY</h2>
      <p>
        My name is Maria Burns. I have been married for ten years and have three
        children of our own. I come from a large family of 11 brothers and
        sisters and about 30 nephews and nieces. About six years ago, I became a
        foster parent and that is when I realized that giving birth does not
        make you a parent, being there for his/her first steps, waking up every
        two hours for feedings or because they are sick. Just being there to
        give them your undivided attention no matter how busy you seem to be,
        that is what makes a parent.
      </p>
      <p>
        I have a few friends that were going through a rough time conceiving, so
        I talked it over with my husband that I would like to help a couple have
        a baby. He was really supportive about it. We gave up foster parenting
        about three years ago, and I did some research about surrogates. That is
        when I found Angela Figueroa and met a wonderful couple, Christine and
        Brad.
      </p>
      <p>
        There was a lot of paperwork and tests to be done before we could
        proceed. But, once everything was in motion, we were forward with our
        first transfer attempt in February. We were all so nervous because there
        was only two embryos to transfer and then having to wait those ten days
        for a pregnancy test was torture for all of us involved. The wait was
        well worth it. Trinity Marie was born nine months later.
      </p>
      <blockquote>
        <i class="icofont-quote-left"></i>
        I was honored by the name they chose to give their daughter. Trinity -
        because it took three of us for Trinity to be born, and Trinity's middle
        name, Maria. I was very surprised and honored when I heard her full
        name. It was so sweet of them to mention my name in their birth
        announcement. That was something I was not expecting at all.
      </blockquote>
      <p>
        I want to thank Christine and Brad for letting me be part of their
        special event in their life. It was well worth everything. When I saw
        Christine and Brad's face when their daughter was born. When people ask
        me if I could ever do it again, I just think of Christine and Brad's
        face and smile nodding my head, yes.
      </p>

      <p>
        Being a surrogate was an amazing and rewarding experience, one I will
        never forget.
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss5.png" alt="Service" />
            </div>
            <p>-Maria</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="storyNumber === 6" class="service-details-area ptb-100">
  <div class="container">
    <div class="services-details-img">
      <h2>SUSAN AND STEPHEN'S STORY</h2>
      <p>Dear Angela:</p>

      <blockquote>
        <i class="icofont-quote-left"></i>
        Thank you from the heart for the important role you played in making our
        dream come true! Although we never gave up on our great desire to have a
        second child, the doctors told us that fulfilling our dream would take a
        good deal of patience, time, motivation and effort. When we located
        NESPA, Inc. and met you, our long journey became so much more pleasant
        and promising!
      </blockquote>

      <p>
        From the start, you treated us with a great deal of respect. You were
        highly professional; yet you were always willing to share your own
        personal stories with us and offer us a funny joke and a warm hug when
        we visited you. Your selection of egg donors was impressive. With your
        help, we located the perfect donor. We have maintained an anonymous yet
        very personable and meaningful relationship with our donor for the past
        year. You have facilitated our correspondence with our donor in a very
        reliable and expedient manner. Likewise, we know that we have found a
        wonderful friend in you.
      </p>
      <p>
        Five months ago, the hospital called to tell us that we are expecting a
        baby. The joy we experienced and continue to experience is immeasurable.
        We are thrilled beyond words and feel very fortunate and blessed. In our
        opinion, no amount of effort was too great for the results that
        transpired. Our ten-year-old son is counting the days until his new baby
        sister arrives!
      </p>
      <p>
        If it were not for you, Angela, we would not be experiencing this joy.
        We know that many other couples would dearly love to have a child, yet
        are unable to do so without assistance. We urge them to make the call
        and the visit to you that may very well change their lives forever. We
        made the call and we are so grateful that we did! Your rates are
        reasonable, your selection of donors is extensive, and your treatment of
        clients is top notch.
      </p>
      <p>
        Thank you so much, Angela. We wish you continued health, happiness and
        success as you so selflessly serve the needs of others in their quest
        for fulfillment through the gift of children.
      </p>
    </div>
    <div class="col-lg-7">
      <div class="service-details-inner">
        <div class="row">
          <div class="col-lg-3">
            <div class="img">
              <img src="assets/img/stories/ss6.png" alt="Service" />
            </div>
            <p>-Susan & Stephen</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="doctors-area doctors-area-two pt-100">
  <div class="doctor-shape">
    <img src="assets/img/stories/2.png" alt="Shape" />
  </div>

  <div class="container">
    <h2 class="m-0">Success Stories</h2>
    <hr />
    <div class="row">
      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss1.png" alt="Doctor" />

            <a routerLink="/stories-details/1">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/1">Joy</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss2.png" alt="Doctor" />

            <a routerLink="/stories-details/2">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/2">Chris Payne</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss3.png" alt="Doctor" />

            <a routerLink="/stories-details/3">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/3">Lisa</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss4.png" alt="Doctor" />

            <a routerLink="/stories-details/4">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/4">Tracy</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss5.png" alt="Doctor" />

            <a routerLink="/stories-details/5">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/5">Maria</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-3 col-lg-3 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss6.png" alt="Doctor" />

            <a routerLink="/stories-details/6">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/6">Susan and Stephen</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
