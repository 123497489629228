import { Component, OnInit } from "@angular/core";
import { SessionService } from "src/app/components/guards/session.service";
import { UserType } from "src/app/components/layouts/models/usertype";
import { IUser } from "src/app/components/models/user";

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
@Component({
  selector: "sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  constructor(private session: SessionService) {
    this.user = session.user;
    this.generateSidebarItems();
  }

  user: IUser;
  ROUTES: RouteInfo[] = [];
  public menuItems: any[];

  ngOnInit() {
    this.menuItems = this.ROUTES.filter((menuItem) => menuItem);
  }

  public get appRoute(): string {
    if (this.user.userType) {
      return this.session.getAppRouteLink(this.user.userType);
    }
    return "";
  }

  public get title(): string {
    if (this.session.user.userType) {
      return this.session.getUserTitle(this.user.userType);
    }
    return "";
  }

  generateSidebarItems() {
    if (Number(this.user.userType) === UserType.Admin) {
      this.ROUTES = [
        {
          path: "/auth/admin/dashboard",
          title: "Dashboard",
          icon: "icofont-dashboard-web",
          class: "",
        },
        {
          path: "/auth/profile",
          title: "Profile",
          icon: "icofont-ui-user",
          class: "",
        },
        {
          path: "/auth/admin/email-management",
          title: "Settings",
          icon: "icofont-ui-settings",
          class: "",
        },
        {
          path: "/admin",
          title: "Logout",
          icon: "icofont-logout",
          class: "",
        },
      ];
    } else {
      this.ROUTES = [
        {
          path: "/auth/user-dashboard",
          title: "Dashboard",
          icon: "icofont-dashboard-web",
          class: "",
        },
        {
          path: "/auth/profile",
          title: "Profile",
          icon: "icofont-ui-user",
          class: "",
        },
        {
          path: this.appRoute,
          title: this.title,
          icon: "icofont-list",
          class: "",
        },
        {
          path: "/auth/search/favorite",
          title: "Favorite",
          icon: "icofont-favourite",
          class: "",
        },
        {
          path: "/login",
          title: "Logout",
          icon: "icofont-logout",
          class: "",
        },
      ];
    }
  }
}
