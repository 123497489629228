<div class="page-title-area page-title-resources">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>TELL ME MORE ABOUT BECOMING</h2>
      </div>
    </div>
  </div>
</div>

<section class="privacy-area pt-100">
  <div class="container">
    <div class="privacy-item">
      <h2>EDUCATION & RESOURCES FOR COLLABORATIVE REPRODUCTION</h2>
      <p>
        This section recommends a selection of educational resources for
        learning more about collaborative reproduction – and information about
        community resources that assist collaborative reproduction – doctors,
        mental health professionals, lawyers, and others.
      </p>
    </div>
    <div class="privacy-item">
      <h2>EDUCATIONAL RESOURCES/RECOMMENDED READING</h2>
      <ul>
        <li>
          <i class="icofont-simple-right"></i> A Matter of Trust: The Guide to
          Gestational Surrogacy by Gail Dutton –
          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/0965596605/qid=1055346655/sr=8-2/ref=sr_8_2/104-9532949-3921516?v=glance&amp;s=books&amp;n=507846"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>
        <li>
          <i class="icofont-simple-right"></i>Choosing Assisted Reproduction:
          Social, Emotional and Ethical Considerations by Susan Lewis Cooper and
          Ellen Sarasohn Glazer – Purchase Now from Amazon – and from the same
          authors, Beyond Infertility: The New Paths to Parenthood
        </li>
        <li>
          <i class="icofont-simple-right"></i> Miracle Child: Genetic Mother,
          Surrogate Womb by Cheryl Saban –

          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/0882821229/qid=1055346773/sr=1-7/ref=sr_1_7/104-9532949-3921516?v=glance&amp;s=books"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>
        <li>
          <i class="icofont-simple-right"></i> Mommy, Did I Grow In Your Tummy?
          Where Some Babies Come From by Elaine R. Gordon, Ph.D. –
          <a
            href="https://www.amazon.com/exec/obidos/ASIN/0963456105/qid%3D1055346831/sr%3D11-1/ref%3Dsr_11_1/104-9532949-3921516"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>
        <li>
          <i class="icofont-simple-right"></i> Surrogate Motherhood: Conception
          in the Heart by Helena Ragoné –
          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/081331979X/qid=1055346862/sr=1-1/ref=sr_1_1/104-9532949-3921516?v=glance&amp;s=books"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>

        <li>
          <i class="icofont-simple-right"></i> Between Strangers by Lori B.
          Andrews –
          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/0060160586/qid=1055346907/sr=1-2/ref=sr_1_2/104-9532949-3921516?v=glance&amp;s=books"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>

        <li>
          <i class="icofont-simple-right"></i> Surrogate Parenting by Amy
          Zuckerman Overvoid –
          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/0345352254/qid=1055346935/sr=1-8/ref=sr_1_8/104-9532949-3921516?v=glance&amp;s=books"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>

        <li>
          <i class="icofont-simple-right"></i> In Vitro Fertilization by
          Geoffrey Sher, M.D. –
          <a
            href="https://www.amazon.com/exec/obidos/tg/detail/-/0816038279/qid=1055347016/sr=1-2/ref=sr_1_2/104-9532949-3921516?v=glance&amp;s=books"
            target="_blank"
            >Purchase Now</a
          >
          from Amazon
        </li>
      </ul>
    </div>
    <div class="privacy-item">
      <h2>COMMUNITY RESOURCES</h2>
      <p>
        The NEEDS collaborative reproduction community includes medical,
        psychiatric, and legal partners as well as the women who serve as egg
        donors and surrogates. Following are some members of the NEEDS
        community.
      </p>
    </div>

    <div class="privacy-item">
      <h2>Infertility Clinics</h2>
      <p>
        Fertility Center of New England Reproductive Science Center Women &
        Infants' Division of Reproductive Medicine and Infertility New England
        Medical Center IVF Mass General Hospital, Vincent IVF Unit Brigham and
        Women's Hospital Baystate Reproductive Medicine Boston IVF Cardone &
        Associates Reproductive Medicine and Infertility
      </p>
    </div>

    <div class="privacy-item">
      <h2>Clinical Psychologists</h2>
      <p>
        NEEDS has longstanding relationships with clinical psychologists
        experienced in collaborative reproduction. Referrals are provided to
        clients.
      </p>
    </div>

    <div class="privacy-item">
      <h2>Legal Counsel</h2>
      <p>
        NEEDS has longstanding relationships with attorneys experienced in
        collaborative reproduction. Referrals are provided to clients.
      </p>
    </div>

    <div class="privacy-item">
      <h2>General Resources</h2>
      <a href="https://resolve.org/" target="_blank">www.resolve.org </a>
    </div>

    <div class="privacy-item">
      <h2>American Society of Reproductive Medicine</h2>
      <a href="https://asrm.org" target="_blank">www.asrm.org</a>
    </div>
  </div>

  <div class="pt-100 pb-100">
    <app-story-lookup></app-story-lookup>
  </div>
</section>
