import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MessageService } from "primeng/api";
import { LeadService } from "../../service/leads/lead.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.scss"],
  providers: [LeadService],
})
export class ContactComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private service: LeadService,
  ) {}

  currentYear: number;
  message: string;
  messageFeedback: string;

  ngOnInit(): void {
    const date = new Date();
    this.currentYear = date.getFullYear();
  }

  public formFeedback: FormGroup = this.fb.group({
    email: ["", Validators.required],
    name: ["", Validators.required],
    subject: ["", Validators.required],
    phoneNumber: [""],
    message: [""],
  });

  async feedback() {
    if (this.formFeedback.valid) {
      const response = await this.service.contactUs(this.formFeedback.value);
      if (response) {
        this.messageService.add({
          severity: "success",
          summary: "Success",
          detail: `Your feedback has been submit, thank you! 🎉`,
        });
        this.messageFeedback = `Your feedback has been submit, thank you! 🎉`;
        this.formFeedback.disable();
      } else {
        this.message = this.service.errorMessage;
      }
    }
  }
}
