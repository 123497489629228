<div class="page-title-area page-title-five">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>TELL ME MORE ABOUT BECOMING</h2>
        <ul>
          <li>
            <i class="icofont-simple-right"></i
            ><a routerLink="/">
              A Parent with the help of egg donation or surrogacy</a
            >
          </li>
          <br />
          <li>
            <i class="icofont-simple-right"></i>
            <a routerLink="/">An Egg Donor </a>
          </li>
          <br />
          <li>
            <i class="icofont-simple-right"></i>
            <a routerLink="/">A Surrogate</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<section class="doctors-area doctors-area-two">
  <div class="doctor-shape">
    <img src="assets/img/stories/2.png" alt="Shape" />
  </div>

  <div class="container">
    <div class="section-title-two">
      <h2>Success Stories</h2>
      <hr />
    </div>
    <div class="row">
      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss1.png" alt="Doctor" />

            <a routerLink="/stories-details/1">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/1">Joy</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss2.png" alt="Doctor" />

            <a routerLink="/stories-details/2">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/2">Chris Payne</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss3.png" alt="Doctor" />

            <a routerLink="/stories-details/3">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/3">Lisa</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss4.png" alt="Doctor" />

            <a routerLink="/stories-details/4">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/4">Tracy</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss5.png" alt="Doctor" />

            <a routerLink="/stories-details/5">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/5">Maria</a>
            </h3>
          </div>
        </div>
      </div>
      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss6.png" alt="Doctor" />

            <a routerLink="/stories-details/6">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/6">Susan and Stephen</a>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
