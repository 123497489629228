<section class="doctors-area pt-100">
  <div class="container">
    <div class="section-title-two">
      <h2>Success Stories</h2>
      <hr />
    </div>

    <div class="row">
      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".3s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss1.png" alt="Doctor" />

            <a routerLink="/stories-details/1">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/1">Joy</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".5s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss2.png" alt="Doctor" />

            <a routerLink="/stories-details/2">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/3">Chris Payne</a>
            </h3>
          </div>
        </div>
      </div>

      <div
        class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow animate__animated animate__fadeInUp"
        data-wow-delay=".7s"
      >
        <div class="doctor-item">
          <div class="doctor-top">
            <img src="assets/img/stories/ss3.png" alt="Doctor" />

            <a routerLink="/stories-details/4">Read more</a>
          </div>

          <div class="doctor-bottom">
            <h3>
              <a routerLink="/stories-details/4">Lisa</a>
            </h3>
          </div>
        </div>
      </div>
    </div>

    <div class="doctor-btn">
      <a routerLink="/stories">See All</a>
    </div>
  </div>
</section>
