import { Component, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { filter } from "rxjs/operators";
declare let $: any;
@Component({
  selector: "app-dash-layout",
  templateUrl: "./dash-layout.component.html",
  styleUrls: ["./dash-layout.component.scss"],
})
export class DashLayoutComponent implements OnInit {
  location: any;
  routerSubscription: any;
  constructor(private router: Router) {}

  ngOnInit() {
    this.recallJsFunctions();
  }

  recallJsFunctions() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        $(".loader").fadeIn("slow");
      }
    });
    this.routerSubscription = this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationEnd || event instanceof NavigationCancel,
        ),
      )
      .subscribe((event) => {
        $.getScript("../assets/js/custom.js");
        $(".loader").fadeOut("slow");
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      });
  }
}
