import { Component } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { SessionService } from "../../guards/session.service";
import { UserService } from "../../service/user/user.service";
import { UserType } from "../../layouts/models/usertype";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.scss"],
})
export class AdminLoginComponent {
  constructor(
    private fb: FormBuilder,
    private service: UserService,
    private auth: SessionService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.auth.clearSession();
  }

  errorMessage: string;

  public form: FormGroup = this.fb.group({
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });

  async submit() {
    if (this.form.valid) {
      const result = await this.service.login(this.form.value);
      if (result) {
        this.auth.user = result;
        if (result.userType === UserType.Admin) {
          this.router.navigate(["/auth/admin/dashboard"]);
        } else {
          this.errorMessage = "Wrong username/password";
        }
      } else {
        this.errorMessage = this.service.errorMessage;
      }
    }
  }
}
