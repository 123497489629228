import { Injectable } from "@angular/core";
import { ApiService } from "../api.services";
import { HttpClient } from "@angular/common/http";
import { IUser } from "../../models/user";

@Injectable({
  providedIn: "root",
})
export class UserService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  public async create(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("user", user);
    return response.status ? response.innerData : null;
  }

  public async update(user: IUser): Promise<IUser> {
    const response = await this.putResponse<IUser>("user", user);
    return response.status ? response.innerData : null;
  }

  public async login(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("user/login", user);
    return response.status ? response.innerData : null;
  }

  public async loginUser(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("user/login-user", user);
    return response.status ? response.innerData : null;
  }

  public async resetPassword(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>("user/resetpassword", user);
    return response.status ? response.innerData : null;
  }

  public async requestResetPassword(user: IUser): Promise<IUser> {
    const response = await this.postResponse<IUser>(
      "user/requestResetPassword",
      user,
    );
    return response.status ? response.innerData : null;
  }

  public async get(id: number): Promise<IUser> {
    const response = await this.getResponse<IUser>(`user/${id}`);
    return response.status ? response.innerData : null;
  }

  public async getAll(): Promise<IUser[]> {
    const response = await this.getResponse<IUser[]>(`user`);
    return response.status ? response.innerData : null;
  }
}
