<div class="sidebar-wrapper">
  <div class="logo">
    <a routerLink="/auth/profile" class="simple-tex">
      <div class="logo-image-small">
        <img src="assets/img/logo-two.png" />
      </div>
    </a>
    <a routerLink="/auth/profile" class="simple-text">
      Hi, {{ user.firstName }}
    </a>
  </div>
  <ul class="nav">
    <li
      *ngFor="let menuItem of menuItems"
      routerLinkActive="active"
      class="{{ menuItem.class }}"
    >
      <a [routerLink]="[menuItem.path]">
        <i class="{{ menuItem.icon }}"></i>
        <h5>{{ menuItem.title }}</h5>
      </a>
    </li>
  </ul>
</div>
