<div class="page-title-area page-title-surrogate">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>SURROGACY</h2>
      </div>
    </div>
  </div>
</div>

<div class="service-details-area">
  <div class="container ptb-100">
    <div class="services-details-img">
      <p>
        Serving as a surrogate carrier is a challenging job, but you experience
        an enormous emotional reward through helping infertile couples fulfill
        their dream of having a baby. To be considered for surrogacy, you must
        be a healthy, drug-free woman between the ages of 21 and 40 who has
        already successfully carried at least one child of your own to term.
        It's also important to know that, if selected as a surrogate, you must
        be willing to undergo extensive medical testing and take fertility drugs
        as required for surrogacy process. And most importantly, you must be
        willing to have an extremely close relationship with the intended
        parents during the pregnancy.
      </p>

      <blockquote>
        Compensation for the time and effort of being a gestational surrogate
        carrier is negotiated between the surrogate and parents. NEEDS
        recommends compensation of at least $25,000 for a single birth and
        $30,000 for twins.
      </blockquote>

      <blockquote>
        <a routerLink="/become-surrogate">Click here</a> for a Step-by-Step
        Guide to becoming a Surrogate.
      </blockquote>
    </div>
  </div>
</div>
