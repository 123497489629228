import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "../../service/user/user.service";
import { SessionService } from "../../guards/session.service";
import { provideNgxMask } from "ngx-mask";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
  providers: [provideNgxMask()],
})
export class SignupComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private service: UserService,
    private router: Router,
    private auth: SessionService,
  ) {}

  errorMessage: string;
  ngOnInit(): void {}

  public form: FormGroup = this.fb.group({
    firstName: new FormControl("", [Validators.required]),
    lastName: new FormControl("", [Validators.required]),
    phoneNumber: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(6),
    ]),
    confirmPassword: new FormControl("", [Validators.required]),
    userType: new FormControl("-1", [Validators.required]),
  });

  async submit() {
    if (this.form.valid) {
      if (
        this.form.controls["password"].value !==
        this.form.controls["confirmPassword"].value
      ) {
        this.errorMessage = "Passwords do not match.";
        return;
      }
      const result = await this.service.create(this.form.value);
      if (result) {
        this.auth.user = result;
        const route = this.auth.getAppRouteLink(result.userType);
        this.router.navigate([route]);
      } else {
        this.errorMessage = this.service.errorMessage;
      }
    }
  }
}
