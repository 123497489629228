import { Injectable } from "@angular/core";
import { SessionStorage } from "ngx-webstorage";
import { IUser } from "../models/user";
import { UserType } from "../layouts/models/usertype";

@Injectable()
export class SessionService {
  @SessionStorage("user")
  private _user: IUser;

  @SessionStorage()
  public get user(): IUser {
    return this._user;
  }

  public set user(val: IUser) {
    this._user = val;
  }

  @SessionStorage("appId")
  private _appId: number;

  @SessionStorage("viewMode")
  public viewMode: boolean;

  @SessionStorage()
  public get appId(): number {
    if (this.user.applicationId) {
      return this.user.applicationId;
    }
    return this._appId;
  }

  public set appId(val: number) {
    this._appId = val;
  }

  public clearSession() {
    this._user = this._appId = null;
  }

  public getUserTitle(type: UserType): string {
    switch (Number(type)) {
      case UserType.Admin:
        return "Admin";
      case UserType.EggDonor:
        return "Egg Donor";
      case UserType.Surrogate:
        return "Surrogate";
      case UserType.IntendedParents:
        return "Intended Parents";
    }
  }

  public getAppRouteLink(type: UserType): string {
    switch (Number(type)) {
      case UserType.EggDonor:
        return "/auth/donor/step1/" + this.appId;
      case UserType.Surrogate:
        return "/auth/surrogate/step1/" + this.appId;
      case UserType.IntendedParents:
        return "/auth/intended-parents/" + this.appId;
    }
  }
}
