import { Component } from "@angular/core";

@Component({
  selector: "app-egg-donation",
  templateUrl: "./egg-donation.component.html",
  styleUrls: ["./egg-donation.component.scss"],
})
export class EggDonationComponent {
  openFile(fileName: string) {
    window.open(`assets/needs-documents/${fileName}.pdf`, "_blank");
  }
}
