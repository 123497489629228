import { Component, OnInit } from "@angular/core";
import { BaseLayout } from "../../layouts/baselayout";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-stories-details",
  templateUrl: "./stories-details.component.html",
  styleUrls: ["./stories-details.component.scss"],
})
export class StoriesDetailsComponent extends BaseLayout implements OnInit {
  constructor(route: ActivatedRoute) {
    super(route);
  }

  public storyNumber: number;

  async ngOnInit() {
    this.routes.params.subscribe((s) => {
      this.storyNumber = Number(s.id);
    });
  }
}
