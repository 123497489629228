import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { Authenticate } from "./authenticate.service";
import { UserType } from "../layouts/models/usertype";
import { SessionService } from "./session.service";

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    protected router: Router,
    protected session: SessionService,
    protected auth: Authenticate,
  ) {}
  protected isAuthorized: boolean = false;

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    if (!this.auth.token) {
      this.isAuthorized = false;
    }
    if (this.auth.token && this.auth.User) {
      this.isAuthorized = true;
      if (this.auth.User.userType !== UserType.Admin && this.session.viewMode) {
        const routes = ["step3", "step4", "step5", "step6"];
        if (state.url.includes("surrogate")) {
          routes.shift();
          routes.shift();
        }
        if (routes.some((route) => state.url.includes(route))) {
          await this.router.navigate(["/auth/user-dashboard"]);
        }
      }
    }
    if (!this.isAuthorized) {
      if (this.auth.User) {
        this.auth.ClearSession();
        await this.router.navigate([""]);
      } else {
        const params = { queryParams: { rquestUrl: state.url } };
        if (params.queryParams.rquestUrl === "/") {
          params.queryParams = null;
        }
        await this.router.navigate(["/"], params);
      }
    }
    return this.isAuthorized;
  }

  async canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.canActivate(route, state);
  }
}
