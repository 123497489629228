<div class="page-title-area page-title-surrogate">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>SURROGACY</h2>
      </div>
    </div>
  </div>
</div>

<div class="service-details-area ptb-100">
  <div class="container">
    <h3>STEP-BY-STEP GUIDE TO BECOMING A SURROGATE</h3>
    <hr />
    <div class="services-details-img">
      <p>
        The following describes in step-by-step detail the process of applying
        to join the NEEDS community and - if selected as a donor - preparing for
        and becoming an egg donor for aspiring parents who select you.
      </p>
      <!-- 
      <blockquote>
        <ul>
          <li>Qualifications Self-Review</li>
          <li>Application and Personal Photographs</li>
          <li>Consent Documents</li>
          <li> Pre-Qualification as Prospective Donor</li>
          <li>Selection and Confirmation as a Donor</li>
          <li>Screening</li>
          <li>Fertility Drugs and Monitoring</li>
          <li>Egg Retrieval/Transfer</li>
          <li>Compensation</li>
        </ul>
      </blockquote> -->

      <h3>1. Qualifications Self-Review</h3>
      <p>
        Your first step, prior to applying for the NEEDS surrogate gestational
        carrier program, is to confirm with yourself that you meet the NEEDS
        qualification criteria for surrogacy. To become a surrogate gestational
        carrier, you must:
      </p>

      <ul>
        <li>Be between 21 and 40 years old</li>
        <li>Have a weight appropriate for your height</li>
        <li>Be ovulating normally</li>
        <li>Be a non-smoker who maintains a healthy lifestyle</li>
        <li>
          Have successfully carried at least one child of your own to term
        </li>
      </ul>

      <blockquote>
        Additionally, think carefully about your willingness to be pregnant and
        to commit a year to a year and a half of your life to helping aspiring
        parents have a baby (or babies). Though immensely rewarding for many who
        do it, being a surrogate gestational carrier is an intense, challenging,
        and emotional process. (Note, though, that as a gestational carrier, the
        baby is biologically the offspring of the parents - he or she has no
        genetic connection to you.)
      </blockquote>

      <h3>2. Application and Personal Photographs</h3>
      <p>
        To join the NEEDS surrogate gestational carrier program - and to help
        aspiring parents make an informed selection of their ideal surrogate -
        you must complete a comprehensive online application/questionnaire,
        which includes detailed questions about you and your health. This
        questionnaire should take roughly 45 minutes to an hour complete online.
      </p>

      <blockquote>
        We also ask that you provide photographs of yourself, which helps
        aspiring parents in the selection process. The photograph should be in a
        digital (electronic) format allowing you to easily upload the photo to
        the NEEDS website. (If you do not have digital photographs, your local
        photofinisher can create them from prints or - if necessary - you can
        mail the prints to NEEDS for scanning and loading to the site.) If you
        decide to apply for NEEDS' program, please review our photo requirements
        document for detailed instructions before submitting photographs.
      </blockquote>

      <p>
        Except for your name and other confidential identifying information,
        your completed application and photos will be seen by aspiring parents
        searching for someone of your characteristics to be their surrogate
        gestational carrier. Your name will not be revealed to the aspiring
        parents until you and the parents have met and agreed to proceed with
        the surrogacy process.
      </p>

      <h3>3. Consent Documents</h3>
      <blockquote>
        To join the NEEDS community, you also must electronically sign consent
        documents with NEEDS indicating your willingness to become a surrogate
        gestational carrier and agreement to allow aspiring parents to review
        online your photograph and your application (again, you remain anonymous
        - your last name and identifying information will not be revealed to
        aspiring parents until you meet and agree to the surrogacy process).
      </blockquote>

      <h3>4. Pre-Qualification</h3>
      <p>
        NEEDS will review your application to pre-qualify you as a surrogate
        gestational carrier, and may also conduct an interview with you. We also
        must receive your electronically signed Consent Documents. Once
        pre-qualified, you will be notified via e-mail of your acceptance into
        the NEEDS community as a prospective surrogate carrier. Your application
        and photograph will then be available online, in a secure location on
        the NEEDS website, for review by aspiring parents whose online search
        for a surrogate indicates that you offer the characteristics they seek.
      </p>

      <h3>5. Initial Selection as Surrogate Carrier</h3>
      <blockquote>
        Selection of a surrogate carrier is an extremely subjective process.
        Aspiring parents typically look for a surrogate who is healthy and
        stable as well as someone they believe they can comfortably work with
        through the close, emotional process of surrogate pregnancy. There's no
        guarantee of when you will be selected as a surrogate carrier. It is
        possible you could be chosen in one week, one month, or one year from
        the time you complete your application and have been accepted into the
        NEEDS surrogate gestational carrier program.
      </blockquote>

      <p>
        Once you are selected by the aspiring parents, you will be immediately
        contacted by NEEDS to notify you of their interest and to confirm your
        willingness to be a surrogate. Brief biographies of the aspiring parents
        may be sent to you for your review. You will be free to ask additional
        questions of the aspiring parents after reading their brief profile.
      </p>

      <h3>6. Introduction to Aspiring Parents</h3>
      <blockquote>
        A telephone conference call will then be scheduled with NEEDS, the
        aspiring parents and you. If the telephone meeting is successful - if
        you, your partner, and the aspiring parents remain interested in
        proceeding with the process - then NEEDS will ask the aspiring parents
        to complete their paperwork with NEEDS. Once the paperwork is completed,
        the identifying information on all parties will be released. The
        aspiring parents will be encouraged to schedule a face-to-face meeting
        with you and your partner to further get to know each other.
      </blockquote>

      <h3>7. Psychological Evaluation</h3>
      <p>
        Once you are chosen by aspiring parents as their surrogate carrier, you
        will be asked to meet with a licensed psychologist for an assessment of
        your mental and emotional readiness for the surrogacy process. This
        evaluation will be paid for by the aspiring parents.
      </p>

      <h3>8. Fee Negotiation and Legal Contract</h3>
      <blockquote>
        The fee for the service of carrying and delivering the aspiring parent's
        biological baby is determined by you. Although being a surrogate is a
        priceless gift, most surrogates request - and most aspiring parents
        expect to compensate - $25,000 for a single birth and $30,000 for twins,
        in addition to expenses. Once you agree upon a fee with the aspiring
        parents, they will present a contract to you. (Note that the contract
        involves you and the aspiring parents - not NEEDS.) You will then meet
        with an attorney of your choice, paid for by the couple, who will review
        the contract with you and negotiate any changes before you sign.
      </blockquote>

      <h3>9. Pregnancy</h3>
      <blockquote>
        The medical procedure for the surrogate gestational carrier is similar
        to having a pap smear and in most cases is pain-free. Your menstrual
        cycle and the menstrual cycle of the biological mother will be
        synchronized using daily injections of Lupron. You will also be taking
        Estrogen prior to ovulation, and the mother will be taking daily
        injections of fertility drugs. The development of her eggs will be
        monitored by daily blood work and ultrasounds.
      </blockquote>

      <p>
        The thickness of the lining of your uterus will be evaluated using blood
        tests and ultrasounds. Three days after the eggs have been retrieved
        from the biological mother and fertilized with the sperm of the
        biological father, the physician will transfer the embryos to you. The
        procedure feels similar to a pap smear and is completed under sterile
        conditions. Depending on the physician, you may then begin progesterone
        injections.
      </p>

      <blockquote>
        A pregnancy test will be taken in approximately 10 days after the
        transfer of embryos to you. Once pregnant, you will carry the aspiring
        parents' baby (or babies) to term, maintaining a close, personal
        relationship with the parents throughout the process until delivery.
      </blockquote>

      <div class="blog-bottom">
        <a routerLink="/signup">Sign Up</a> to become an Egg Surrogate.
      </div>
    </div>
  </div>
</div>
