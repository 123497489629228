<div class="page-title-area page-title-donor">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>FOR DONORS</h2>
      </div>
    </div>
  </div>
</div>

<div class="service-details-area ptb-100">
  <div class="container">
    <h3>STEP-BY-STEP GUIDE TO BECOMING AN EGG DONOR</h3>
    <hr />
    <div class="services-details-img">
      <p>
        The following describes in step-by-step detail the process of applying
        to join the NEEDS community and - if selected as a donor - preparing for
        and becoming an egg donor for aspiring parents who select you.
      </p>
      <!-- 
      <blockquote>
        <ul>
          <li>Qualifications Self-Review</li>
          <li>Application and Personal Photographs</li>
          <li>Consent Documents</li>
          <li> Pre-Qualification as Prospective Donor</li>
          <li>Selection and Confirmation as a Donor</li>
          <li>Screening</li>
          <li>Fertility Drugs and Monitoring</li>
          <li>Egg Retrieval/Transfer</li>
          <li>Compensation</li>
        </ul>
      </blockquote> -->

      <h3>1. Qualifications Self-Review</h3>
      <p>
        Your first step, prior to applying for the NEEDS egg donor program, is
        to confirm with yourself that you meet the NEEDS qualification criteria
        for egg donors. To become an egg donor, you must:
      </p>

      <ul>
        <li>Be between 20 and 30 years old</li>
        <li>Be at least 5'2" tall</li>
        <li>Have an appropriate weight for your height</li>
        <li>Be a non-smoker who maintains a healthy lifestyle</li>
      </ul>

      <blockquote>
        It is also preferable that you have a college degree - or are pursuing a
        degree. Note that if you have had your tubes tied, this will not
        interfere with the egg donation/retrieval procedure.
      </blockquote>

      <h3>2. Application and Personal Photographs</h3>
      <p>
        To join the NEEDS egg donor program - and to help aspiring parents make
        an informed selection of their ideal egg donor - you must complete a
        comprehensive online application/questionnaire, which includes detailed
        questions about you, your health, and your family background. This
        questionnaire should take roughly 45 minutes to an hour to complete
        online - though you may need to stop while completing the application to
        find out certain information (for example, your grandmother's natural
        hair color!).
      </p>

      <blockquote>
        We also ask that you provide several personal photographs (if
        available), which should be in a digital (electronic) format allowing
        you to easily upload the photos to the NEEDS website. (If you do not
        have digital photographs handy, you can take photos of your printed
        photos using your smartphone and email them to NEEDS for uploading to
        the site.) You are required to provide two photos showing your face and
        shoulders as well as two full-length photos of yourself. It also is
        extremely helpful to aspiring parents if they can see a few photos of
        you as a baby and as a child, as well as photos of your children, if you
        have any. If you decide to apply for NEEDS' program, please review our
        photo requirements document for detailed instructions before submitting
        photographs.
      </blockquote>

      <p>
        Your completed application - except for your name and other confidential
        identifying information - and your photos will be seen by aspiring
        parents searching for someone of your characteristics.
      </p>

      <h3>3. Consent Documents</h3>
      <blockquote>
        To join the NEEDS community, you also must electronically sign consent
        documents with NEEDS indicating your willingness to become an egg donor,
        acceptance of the compensation offered if you are selected and have the
        opportunity to make a donation, and agreement to allow aspiring parents
        to review online your photographs and your application (again, you
        remain anonymous - your last name and identifying information will not
        be revealed to aspiring parents).
      </blockquote>

      <h3>4. Pre-Qualification as Prospective Donor</h3>
      <p>
        NEEDS will review your application to pre-qualify you as a donor, and
        may also conduct a telephone interview with you. We also must receive
        your electronically signed Consent Documents. Once pre-qualified, you
        will be notified via e-mail of your acceptance into the NEEDS community
        as a prospective egg donor. Your application and photographs will then
        be available online, in a secure location on the NEEDS website, for
        review by registered aspiring parents whose online donor search
        indicates that you offer the characteristics they seek in an egg donor.
      </p>

      <h3>5. Selection and Confirmation as a Donor</h3>
      <blockquote>
        Donor selection is an extremely subjective process. Aspiring parents
        typically look for a donor whose physical characteristics and ethnic
        background are similar to theirs. But they also may seek donors with
        certain educational or career backgrounds, or search certain unusual
        characteristics. Therefore, there's no guarantee of when you will be
        selected as a donor. It is possible you could be chosen in one week, one
        month, or one year from the time you complete your application and have
        been accepted into the NEEDS egg donor program.
      </blockquote>

      <p>
        Once you are selected by the aspiring parents, you will be immediately
        contacted by NEEDS to notify you of their interest and to reconfirm your
        availability and your willingness to be an egg donor at that time. You
        will also be given the name and location of the fertility clinic that
        will conduct the egg donation process. Becoming a donor takes time,
        effort, and energy - the hopes and dreams of aspiring parents are
        depending on you - so it will be extremely important that you are fully
        committed to complete the egg donation process.
      </p>

      <h3>6. Screening</h3>
      <blockquote>
        Once you are chosen as an egg donor, you will follow the protocol of the
        medical facility chosen by the aspiring parents.
      </blockquote>
      <p>
        Typically, NEEDS will contact you to schedule a preliminary interview
        with medical facility. During this interview, you will be asked about
        your medical and family history. The program and drug administration
        will be described to you in greater detail. Most medical facilities
        require you to take a psychological test (MMPI) and meet with a mental
        health professional to discuss the emotional issues involved with egg
        donation. Tests will need to be taken to appraise your health and for
        sexually transmitted diseases such as HIV and some infectious diseases,
        including hepatitis. The interviews and tests will be conducted at no
        cost to you. If you are healthy and your tests are negative, you will
        begin the process of egg donation - which takes approximately three
        months.
      </p>

      <h3>7. Fertility Drugs and Monitoring</h3>
      <blockquote>
        A woman's ovaries normally release one egg per cycle during ovulation.
        To be able to retrieve more than one egg from a donor's ovaries,
        fertility drugs are used. You will be administering to yourself daily
        injections of the following: Lupron, Pergonal, Metrodin and Humegon. One
        or a combination of these fertility drugs will be given for about two
        weeks. This means daily morning visits (for approximately 10 days) to a
        medical facility for blood tests and, typically, four ultrasounds. Most
        donors do not have side effects or problems, but some do experience
        bloating, enlargement of ovaries and ovarian cysts.
      </blockquote>

      <h3>8. Egg Retrieval/Transfer</h3>
      <blockquote>
        When the doctors determine that the eggs have matured, an injection of
        HCG is administered to trigger ovulation. About 36 hours after the
        injection, the eggs will be retrieved from you by transvaginal
        ultrasound. Usually general anesthesia is not given, but a mild sedation
        is used to relax you. The procedure lasts approximately 30 minutes and
        is considered by many not to be painful.
      </blockquote>

      <p>
        NEEDS and the medical facility you work with will honor the
        confidentiality and anonymity of you as well as the aspiring parents who
        receive your donated eggs. The identity of the recipient couple will not
        be revealed to you.
      </p>

      <h3>Compensation</h3>
      <blockquote>
        Upon retrieval of your eggs, NEEDS will compensate you with an
        honorarium of $6,000 for your time, inconvenience, effort, and personal
        expense incurred as a result of donating your eggs.
      </blockquote>

      <div class="blog-bottom">
        <a routerLink="/signup">Sign Up</a> to become an Egg Donor.
      </div>
    </div>
  </div>
</div>
