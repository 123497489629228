<div class="page-title-area page-title-aspiring">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>BECOMING A PARENT VIA EGG DONATION</h2>
      </div>
    </div>
  </div>
  <h4 class="align-items-center">Egg Donation Fees for NEEDS Clients</h4>
</div>

<div class="service-details-area">
  <div class="container ptb-100">
    <div class="services-details-img ptb-100">
      <table class="table table-striped table-bordered">
        <thead></thead>
        <tbody>
          <tr>
            <td>Program Fee:</td>
            <td>
              $4,000 for a local donor $4,500 for an out-of-town donor (a donor
              who needs remote monitoring)
            </td>
          </tr>
          <tr>
            <td>Donor's Fee:</td>
            <td>$7,500 suggested starting fee</td>
          </tr>
        </tbody>
      </table>

      <p>
        Costs for fertility clinic services, including the egg retrieval/in
        vitro fertilization process, are not included. Please discuss medical
        costs with your fertility clinic. NEEDS does not require legal services.
        Please review the consent form at your fertility clinic. After reviewing
        this form, if legal services are requested, NEEDS will refer you to an
        experienced attorney.
      </p>

      <p>Please follow the step-by-step guide below.</p>

      <h3>A STEP-BY-STEP GUIDE TO BECOMING A PARENT VIA EGG DONATION</h3>

      <p>
        The process of working with NEEDS to find a suitable donor and becoming
        a parent via egg donation is straightforward. Following are the six
        steps involved.
      </p>

      <h3>1. Register for a NEEDS Online Account</h3>
      <p>
        First, you complete the intended parent registration form online. You
        immediately will be provided a secure access to our donor database.
      </p>

      <h3>2. Donor Search & Selection</h3>
      <blockquote>
        With your email address and password, you then can search our online
        database of all available egg donors to locate a donor that meets your
        desired characteristics. You can organize your search using search
        criteria to limit the results to those donors most closely matching your
        requirements.
      </blockquote>

      <p>
        You are provided extensive background information with which to make
        your donor selection. NEEDS provides pictures of the Donors, their
        children (if available), and an extensive list of personal
        characteristics. Moreover, an in-depth medical and personal disclosure
        about each donor is provided, based directly on the information the
        donor has provided as part of the NEEDS donor registration and
        pre-screening process.
      </p>

      <h3>3. Donor Confirmation</h3>
      <blockquote>
        Once you have selected online one or more potential donors, NEEDS will
        notify your first-choice donor to confirm her interest and availability
        for donating her eggs. You then will be contacted to confirm your donor
        selection, and the donor will be “placed on hold” (i.e., will not be
        available to other aspiring parents).
      </blockquote>

      <h3>4. Agreements & Fee</h3>
      <p>
        Once you've finalized your selection of a donor, you will print out and
        sign
        <a (click)="openFile('ParentDonorAgreement')" routerLink="/egg-donation"
          >NEEDS’ Donor Agreement</a
        >
        and
        <a
          (click)="openFile('ParentDonorAcknowledgement')"
          routerLink="/egg-donation"
          >Acknowledgement of Intended Parents</a
        >
        documents along with two checks – a check for $6,000 (egg donor’s
        compensation) and $3,700 (the balance of the NEEDS’ fee). We request
        that you return the agreements and payments within three (3) business
        days of your selection. The purpose of this request is to ensure
        fairness to the donor and to other aspiring parents who may be
        interested in her as an egg donor.
      </p>

      <h3>5. Screening & Fertility Drugs</h3>
      <blockquote>
        Once your signed agreements and payments are received, you and the donor
        will begin working anonymously with your fertility clinic for the
        remainder of the egg donation process. This includes a thorough
        psychological and medical screening, administration of fertility drugs
        to the donor to stimulate egg production, and daily monitoring of egg
        maturation.
      </blockquote>

      <h3>5. Egg Retrieval/Transfer</h3>
      <p>
        The final step involves synchronizing the intended mother’s cycle with
        the donor’s cycle (injection of HCG is administered to trigger
        ovulation), and finally, the egg retrieval procedure. It takes an
        average of three months from the time you choose a donor to the time of
        egg retrieval and transfer to the intended mother.
      </p>

      <blockquote>
        Additional Frequently Asked Questions about egg donation are answered in
        our <a routerLink="/faq">FAQs</a> section. If you have further questions
        after reviewing the information on this site, or simply would like
        personal assistance, please do not hesitate to contact NEEDS at
        888-494-4060 or via e-mail at
        <a href="mailto: info@fertilityneeds.com">info@fertilityneeds.com</a>.
        We will be happy to assist you in any way possible as you consider
        taking your first steps to becoming parents with the help of an egg
        donor.
      </blockquote>

      <div class="blog-bottom">
        <a routerLink="/signup">Sign Up</a> as an Aspiring Parent.
      </div>
    </div>
  </div>
</div>
