<div class="page-title-area page-title-faq">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="page-title-item">
        <h2>Frequently Asked Questions</h2>
      </div>
    </div>
  </div>
</div>

<section class="faq-area pt-100 pb-100">
  <div class="container">
    <div class="row faq-wrap">
      <div class="col-lg-12">
        <div class="faq-head">
          <h2>QUESTIONS FREQUENTLY ASKED BY ASPIRING PARENTS</h2>
        </div>
        <div class="faq-item">
          <ul class="accordion">
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <a
                >Q: Does egg donation require a contract between the intended
                parents and the donor?</a
              >
              <p>
                A: There is no requirement for a contract between the intended
                parents and the egg donor, since their identities normally
                remain anonymous. Most intended parents are satisfied with the
                waiver that is signed by the egg donor at the fertility clinic
                handling the in-vitro fertilization (IVF) procedure. However, if
                you desire such a contract, NEEDS can refer you to an attorney
                who is experienced in collaborative reproduction.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".4s"
            >
              <a>Q: What if the donor we select doesn’t work out?</a>
              <p>
                A: Occasionally, for personal or medical reasons, the original
                egg donor selected does not work out. For example, the cycle can
                be cancelled by the fertility clinic. In all such cases, the
                intended parents will pay no additional fee to NEEDS to choose
                another egg donor. NEEDS guarantees that egg retrieval will take
                place.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <a
                >Q: What if the intended parents – or the egg donor – decide
                that they want to meet?</a
              >
              <p>
                A: Normally, the egg donation process is anonymous. Egg donors
                who are interested in meeting the parents are required to
                specify that in advance in the pre-screening process. Parents
                may specify their desire to meet the donor in advance as well.
                In such a case, NEEDS will work with all parties to arrange a
                meeting.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".6s"
            >
              <a>Q: How long does the egg donation process take? </a>
              <p>
                A: On average it takes approximately three months from the time
                the aspiring parents choose a donor to the time of egg retrieval
                and transfer to the intended mother.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row faq-wrap">
      <div class="col-lg-12">
        <div class="faq-head">
          <h2>FREQUENTLY ASKED QUESTIONS ABOUT EGG DONATION</h2>
        </div>
        <div class="faq-item">
          <ul class="accordion">
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <a
                >Q: What are the basic qualifications for becoming an egg
                donor?</a
              >
              <p>
                A: Egg donors should be younger than 31 years old, weigh less
                than 170 pounds, ovulating, non-smoking, and be maintaining a
                healthy lifestyle.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".4s"
            >
              <a>Q: I’ve had my tubes tied. Can I still become an egg donor?</a>
              <p>
                A: Yes, you can. If you have had your tubes tied, this will not
                interfere with the procedure.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <a>Q: How long is the egg donation process for the donor? </a>
              <p>
                A: Once you are registered and qualified as a NEEDS egg donor,
                you could be chosen by intended parents at any time – a week
                from now, a month from now, or longer. Once you are selected and
                agree to donate, it takes approximately three months from the
                time the aspiring parents choose a donor to the time of egg
                retrieval and transfer to the intended mother.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row faq-wrap">
      <div class="col-lg-12">
        <div class="faq-head">
          <h2>FREQUENTLY ASKED QUESTIONS ABOUT GESTATIONAL SURROGACY</h2>
        </div>
        <div class="faq-item">
          <ul class="accordion">
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".3s"
            >
              <a
                >Q: What are the basic requirements for becoming a gestational
                surrogate carrier?</a
              >
              <p>
                A: To be considered for surrogacy, you must be a healthy,
                drug-free woman between the ages of 21 and 40 who has already
                successfully carried at least one child of your own to term.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".4s"
            >
              <a>Q: What is required of a gestational surrogate? </a>
              <p>
                A: Beyond the obvious requirement – willingness to carry a baby
                (or babies) to term on behalf of its parents – if selected as a
                gestational carrier, you must be willing to undergo extensive
                medical testing and take fertility drugs as required for
                surrogacy process. And most importantly, you must be willing to
                have an extremely close relationship with the intended parents
                during the pregnancy.
              </p>
            </li>
            <li
              class="wow animate__animated animate__fadeInUp"
              data-wow-delay=".5s"
            >
              <a>Q: What kind of side effects can I expect?</a>
              <p>
                A: For more information, please visit
                <a
                  routerLink="http://www.stanford.edu/class/siw198q/websites/eggdonor/procedures.html"
                  >www.stanford.edu</a
                >
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-100 pb-100">
    <app-story-lookup></app-story-lookup>
  </div>
</section>
